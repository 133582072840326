import axios from "axios";
import React, {
  useRef,
  useEffect,
  useState,
} from "react";
import CPS from "./helpers/contentProviders";
import Categories from "./helpers/categories";
import "../../styles/components/progress.scss";
import Nav from "../../components/dashboard/nav";
import Restriction from "./helpers/restrictions";
import VideoUploader from "../../components/uploaderSerie/video";
import TagNameUploader from "../../components/uploaderSerie/tagName";
import PortraitUploader from "../../components/uploaderSerie/portrait";
import LandscapeUploader from "../../components/uploaderSerie/landScape.jsx";
import En_subtitleUploader from "../../components/uploaderSerie/En_Subtitle";
import Fr_subtitleUploader from "../../components/uploaderSerie/Fr_Subtitle";

const PostVideo = () => {
  const videoRef = useRef();
  const TagNameRef = useRef();
  const LandscapeRef = useRef();
  const PortraitRef = useRef();
  const En_subtitleRef = useRef();
  const Fr_subtitleRef = useRef();

  const [state, setState] = useState({
    videoName: "",
    videoSynopsis: "",
    videoCast: "",
    category: "Choose",
    landscape: "",
    nameTag: "",
    portrait: "",
    video: "",
    EnSubt: "",
    FrSubt: "",
    licenseEndDate: null,
    type: "serie",
    rate: "Choose",
    isLoading: false,
    file: null,
    uploading: null,
    progressBar: 1,
    progressOwner: "",
    resCountries: [],
    contentProvider: "",
  });

  const finishUpload = () => {
    const countries = [];
    for (const el of state.resCountries) {
      if (el.back === "green") {
        countries.push(el.name);
      }
    }
    const {
      type,
      rate,
      category,
      videoCast,
      videoName,
      videoSynopsis,
      licenseEndDate,
      landscape,
      nameTag,
      portrait,
      EnSubt,
      FrSubt,
    } = state;

    const data = {
      type,
      rate,
      category,
      videoCast,
      videoName,
      videoSynopsis,
      landscape,
      nameTag,
      portrait,
      EnSubt: state.type !== "serie" ? EnSubt : null,
      FrSubt: state.type !== "serie" ? FrSubt : null,
      video: state.type !== "serie" ? state.video : null,
      resCountries:
        countries.toString() === "" ? "valhara" : countries.toString(),
    };

    if (licenseEndDate) {
      data.licenseEndDate = licenseEndDate;
    }
    
    axios
      .post(`${process.env.REACT_APP_API_URL}/temp/new`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("admintoken")}`,
          "Content-Type": "application/json",
          cpid: state.contentProvider,
        },
      })
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          videoName: "",
          videoSynopsis: "",
          videoCast: "",
          category: "Choose",
          landscape: "",
          nameTag: "",
          portrait: "",
          video: "",
          EnSubt: "",
          FrSubt: "",
          licenseEndDate: null,
          type: "Choose",
          rate: "Choose",
          isLoading: false,
          uploadTokens: null,
          file: null,
          uploading: null,
          progressBar: 1,
          contentProvider: "",
        }));

        videoRef.current.reset();
        TagNameRef.current.reset();
        LandscapeRef.current.reset();
        PortraitRef.current.reset();
        En_subtitleRef.current.reset();
        Fr_subtitleRef.current.reset();

        if (response.status === 201) {
          alert("Form Submitted Successfully");
        } else {
          setState((prevState) => ({
            ...prevState,
            uploadStatus: response.message,
          }));
        }
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        alert(error.message);
      });
  };

  useEffect(() => {
    fetch("https://restcountries.eu/rest/v2/all?fields=name;flag;region")
    .then((res) => res.json())
    .then((data) =>
      setState((prevState) => ({
        ...prevState,
        resCountries: data,
      }))
    )
    .catch((err) => console.error(err));
  }, []);

  const handleVideoUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      video: value,
    }));
  };

  const handleTagNameUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      nameTag: value,
    }));
  };

  const handlePortraitUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      portrait: value,
    }));
  };
  const handleLandscapeUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      landscape: value,
    }));
  };
  const handleEn_SubtitleUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      EnSubt: value,
    }));
  };

  const handleFr_SubtitleUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      FrSubt: value,
    }));
  };

  const formChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const addVideo = (e) => {
    e.preventDefault();

    if (
      state.type === "Choose" ||
      state.category === "Choose" ||
      state.rate === "Choose" ||
      state.contentProvider === ""
    ) {
      alert("Enter The Missing Data");
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      finishUpload();
    }
  };

  const restrict = async (type, data) => {
    const { resCountries } = state;

    if (type === "Grant") {
      if (Array.isArray(data)) {
        const newCountries = [];
        for (let i = 0; i < resCountries.length; i++) {
          if (resCountries[i].region === data[0]) {
            newCountries.push({ ...resCountries[i], back: "green" });
          } else {
            newCountries.push(resCountries[i]);
          }
        }
        setState((prevState) => ({
          ...prevState,
          resCountries: newCountries,
        }));
      } else {
        const newCountries = await resCountries.map((el) => {
          if (el.name !== data) {
            return el;
          } else {
            return { ...el, back: "green" };
          }
        });
        setState((prevState) => ({
          ...prevState,
          resCountries: newCountries,
        }));
      }
    } else {
      if (Array.isArray(data)) {
        const newCountries = [];
        for (let i = 0; i < resCountries.length; i++) {
          if (resCountries[i].region === data[0]) {
            newCountries.push({ ...resCountries[i], back: "" });
          } else {
            newCountries.push(resCountries[i]);
          }
        }
        setState((prevState) => ({
          ...prevState,
          resCountries: newCountries,
        }));
      } else {
        const newCountries = await resCountries.map((el) => {
          if (el.name !== data) {
            return el;
          } else {
            return { ...el, back: "" };
          }
        });
        setState((prevState) => ({
          ...prevState,
          resCountries: newCountries,
        }));
      }
    }
  };

  const today = new Date();
  const todayDate = `${today.getFullYear()}-${
    today.getMonth() + 1 < 10 ? "0" : ""
  }${today.getMonth() + 1}-${
    today.getDate() < 10 ? "0" : ""
  }${today.getDate()}`;

  return (
    <div className="hold">
      <div className="container-fluid text-body">
        <Nav page="Creation Serie" />

        <form className="pb-4" onSubmit={(e) => addVideo(e)}>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <h6 className="text-muted">Video Name</h6>
                <input
                  type="text"
                  className="form-control"
                  name="videoName"
                  onChange={(e) => formChangeHandler(e)}
                  value={state.videoName}
                  required={false}
                />
              </div>
            </div>

            <div className="col-lg-2">
              <div className="form-group">
                <h6 className="text-muted">Age Rate</h6>
                <select
                  className="form-control"
                  name="rate"
                  onChange={(e) => formChangeHandler(e)}
                  value={state.rate}
                  required={false}
                >
                  <option>Choose</option>
                  <option>Everyone</option>
                  <option>10+</option>
                  <option>12+</option>
                  <option>16+</option>
                  <option>18+</option>
                </select>
              </div>
            </div>

            <CPS
              width={2}
              handler={(contentProvider) =>
                setState((prevState) => ({
                  ...prevState,
                  contentProvider,
                }))
              }
            />

            <div className="col-lg-2">
              <div className="form-group">
                <h6 className="text-muted">Type</h6>
                <select
                  className="form-control"
                  name="type"
                onChange={(e) => formChangeHandler(e)
                }
                  value="serie"
                  disabled
                  required={false}
                >
                  <option>Choose</option>
                  <option>movie</option>
                  <option>serie</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <h6 className="text-muted">Video Cast</h6>
                <textarea
                  maxLength="150"
                  style={{ resize: "none" }}
                  name="videoCast"
                  className="form-control"
                  rows="2"
                  onChange={(e) => formChangeHandler(e)}
                  value={state.videoCast}
                  required={false}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <h6 className="text-muted">Video Synopsis</h6>
                <textarea
                  style={{ resize: "none" }}
                  name="videoSynopsis"
                  maxLength="250"
                  className="form-control"
                  rows="2"
                  onChange={(e) => formChangeHandler(e)}
                  value={state.videoSynopsis}
                  required={false}
                ></textarea>
              </div>
            </div>
          </div>
          <br />
          <h5 className="text-muted">Files</h5>
          <div className="row mb-3">
            <div className="col-lg-4 my-1">
              <p>Upload Video</p>
              <VideoUploader
                ref={videoRef}
                handleVideoUpload={handleVideoUpload}
                type="movie"
              />
            </div>
            <div className="col-lg-4 my-1">
              <p>320 x 320 Video Tag Name</p>
              <TagNameUploader
                ref={TagNameRef}
                handleTagNameUpload={handleTagNameUpload}
                type="image"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4">
              <p> 1920 x 1080 Landscape Cover</p>
              <LandscapeUploader
                ref={LandscapeRef}
                type="image"
                handleLandscapeUpload={handleLandscapeUpload}
              />
            </div>
            <div className="col-lg-4">
              <p>1080 x 1620 Portrait Cover</p>
              <PortraitUploader
                ref={PortraitRef}
                type="image"
                handlePortraitUpload={handlePortraitUpload}
              />
            </div>
          </div>
          <br />
          <h5 className="text-muted">Subtitles</h5>
          <div className="row mb-3">
            <div className="col-lg-4">
              <p>English Subitle</p>
              <En_subtitleUploader
                ref={En_subtitleRef}
                handleEn_SubtitleUpload={handleEn_SubtitleUpload}
                type="subtitle"
              />
            </div>
            <div className="col-lg-4">
              <p> French Subtitle (optional)</p>
              <Fr_subtitleUploader
                ref={Fr_subtitleRef}
                handleFr_SubtitleUpload={handleFr_SubtitleUpload}
                type="subtitle"
              />
            </div>
          </div>
          <br />
          <h5 className="text-muted">Categories</h5>
          <Categories
            style={{
              zIndex: "1002",
            }}
            state={state.category}
            width={2}
            set={(category) =>
              setState((prevState) => ({
                ...prevState,
                category,
              }))
            }
          />
          <br />
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <h5 className="text-muted">License end date</h5>
                <input
                  min={todayDate}
                  type="date"
                  name="licenseEndDate"
                  onChange={(e) => formChangeHandler(e)}
                  className="form-control"
                  rows="2"
                />
              </div>
            </div>
          </div>
          <br />
          <Restriction
            handler={(type, data) => restrict(type, data)}
            cp={state.contentProvider}
            allCountries={state.resCountries}
            Alignment="flex"
          />
          <button className="btn btn-primary mr-2" type="submit">
            {state.isLoading ? (
              <div
                className="spinner-border spinner-border-sm mr-2"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            {state.type === "serie" ? "Submit Serie" : "Submit Movie"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PostVideo;

import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUserStatus } from '../../actions/signInActions';


class Signin extends React.Component {

	state = {
		email: '',
		password: '',
		signinStatus: '',
		isLoading: false
	}
	usernameChangeHandler = (e) => {
		this.setState({ email: e.target.value })
	}
	passwordChangeHandler = (e) => {
		this.setState({ password: e.target.value })
	}
	loginHandler = (e) => {
		e.preventDefault()
		this.setState({ isLoading: true })
		this.props.setUserStatus('loading', this.state)
		const data = { email: this.state.email, password: this.state.password };
		axios.post(`${process.env.REACT_APP_API_URL}/staff/login`,
			data
			, {
				headers: {
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*",
				}
			})
			.then(response => {
				this.setState({ isLoading: false })
				if (response.status === 200 && response.data.data.role === 'admin') {
					localStorage.setItem('admintoken', response.data.data.token);
					this.setState({ signinStatus: "Login Success" })
					this.props.history.push('/dashboard');
				} else if(response.status === 200 && response.data.data.role !== 'admin') {
					this.setState({ signinStatus: 'Invalid email or password!' })
				} else {
					this.setState({ signinStatus: response.message })
				}
			})
			.catch(error => {
				this.setState({ isLoading: false })
				this.setState({ signinStatus: error.message })
			});
	}

	render() {
		return (
			<React.Fragment>
				<div className="signBg py-3">
					<div className="bg"></div>
					<div className="container">
						<div className="row">
							<div className="col-sm-9 col-md-7 col-lg-5 mx-auto form">
								<div className="card card-signin bg-white text-dark mt-5">
									<div className="card-body">
										{/* <h5 className="card-title text-center pb-2">Admin Sign In</h5> */}
										<i className="fas fa-user-shield"></i>
										<form className="form-signin" onSubmit={this.loginHandler}>
											<div className="form-label-group mb-4">
												<input type="text" className="form-control" placeholder="Email address" required autoFocus
													onChange={this.usernameChangeHandler}
													value={this.state.username}
												/>
											</div>

											<div className="form-label-group mb-4">
												<input type="password" id="inputPassword" className="form-control" placeholder="Password" required
													onChange={this.passwordChangeHandler}
													value={this.state.password}
												/>
											</div>

											{this.state.signinStatus !== '' ? (
												<div className="alert alert-primary alert-dismissible fade show" role="alert">
													{this.state.signinStatus}
													<button type="button" className="close" data-dismiss="alert" aria-label="Close">
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
											) : (this.state.signinStatus === 'Login Success' ? (
												<div className="alert alert-success alert-dismissible fade show" role="alert">
													{this.state.signinStatus}
													<button type="button" className="close" data-dismiss="alert" aria-label="Close">
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
											) : (null))
											}


											<button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">
												{this.state.isLoading ? (
													<div className="spinner-border spinner-border-sm mr-2" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												) : (null)
												}
												Sign in
										</button>
										</form>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
Signin.propTypes = {
	setUserStatus: PropTypes.func.isRequired,
};
const mapStateToProps = state => {
	return {
		signIn: state.signIn,
	}
};

export default connect(mapStateToProps, { setUserStatus })(Signin)
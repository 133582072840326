const compareLicenseDate = (string) => {
    if (string) {
        const dateArray = string.split('-');
        const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
        const difference = (date.getTime()) - (new Date().getTime());
        const daysLeft = Math.ceil(difference / (3600 * 24 * 1000));
        // 864000000 is the milliseconds in 10 days;
        return {
            nearExpiration: difference <= 864000000,
            daysLeft,
        };
    }
    return {
        nearExpiration: false,
        daysLeft: 0,
    }
}
export default compareLicenseDate;
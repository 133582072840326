/* eslint-disable react/style-prop-object */
import axios from 'axios';
import React, { Component } from 'react';
import Modal from '../../components/modal';
import CPS from './helpers/contentProviders';
import '../../styles/components/progress.scss';
import Nav from '../../components/dashboard/nav';
import Restriction from './helpers/restrictions';
import ProgressBar from 'react-customizable-progressbar';
import DataTable from '../../components/dashboard/dataTable';

class Action extends Component {
	constructor() {
		super();
		this.state = {
			video: '',
			isUploading: false,
			videoName:"",
			videoCast:"",
			videoSynopsis:"",
			category:"",
			contentProvider:"",
			videoTrailers:[],
			resCountries:[],
			updateVideoId: '',
			updateStatus: null,
			deleteStatus: null,
			isLoading: false,
			file: '',
			uploadTokens: null,
			progressBar: 1,
			landscape: '',
			nameTag: '',
			portrait: '',
			EnSubt:'',
			FrSubt:'',
		}
	}
	currentVideo = {}
	async componentDidMount() {
		const videoDetails = await axios.get(`${process.env.REACT_APP_API_URL}/video/cp/unique?id=${this.props.row.videoId}`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		});
		const data = await fetch('https://restcountries.eu/rest/v2/all?fields=name;flag;region')
		const res = await data.json();
		if(this.state.resCountries !== undefined && this.state.resCountries !== null && this.state.resCountries !== "" && this.state.resCountries.length > 0){
			const restrictions = this.state.resCountries.split(",");
			const arr = [];
			for(const country of data){
				const { name } = country;
				const exist = await restrictions.find(el => el === name)
				if(exist){
					arr.push({ ...country, back:"green" })
				}else{
					arr.push({ ...country, back:"" })
				}
			}
			this.setState({ resCountries : arr })
		} else{
			this.setState({ resCountries : res })
		}
		this.setState({
			videoName: videoDetails.data.data.videoName,
			category: videoDetails.data.data.category,
			videoCast: videoDetails.data.data.videoCast,
			videoSynopsis: videoDetails.data.data.videoSynopsis,
			updateVideoId: videoDetails.data.data.videoId,
		})
	}

	formChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    fileChangeHandler = (e) => {
        const file = e.target.files[0];
        this.setState({ file })
    }

    complexChangeHandler = e => {
        if(e.target.files[0] !== undefined)
        this.setState({ [e.target.name]: e.target.files[0] })
    }


	finishUpload = () => {
        var trailerData = new FormData();
        trailerData.append('videoId', this.state.updateVideoId);
        trailerData.append('trailer', this.state.uploadTokens.Key);

        axios({
            url: `${process.env.REACT_APP_API_URL}/trailer/new`, method: 'POST',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`, 'Content-Type': 'multipart/form-data' },
            data: trailerData
        })
            .then(response => {
                this.setState({ isLoading: false })
                if (response.status === 201) {
                    alert("Trailer Uploaded Successfully")
                }
                else {
                    this.setState({ uploadStatus: response.message })
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ isLoading: false })
                alert(error.response.data.message)
            });
    }

    uploadToAWS = async mode => {
        if(mode === 'trailer'){
            const file = this.state.file;
            await axios.put(this.state.uploadTokens.url, file, {
                headers: { 'Content-Type': file.type }, onUploadProgress: progressEvent => {
                    let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({ progressBar: progress });
                }
            }).then((res) => {
                this.setState({ progressBar: 1 });
                this.finishUpload(mode);
            })
            .catch((err) => {
                this.setState({ isLoading: false })
                alert(err.response);
            })
        }else{
			const { uploadTokens } = this.state;

            for (let key in uploadTokens) {
				const file = this.state[key];
                if (uploadTokens[key] && file !== '') {
                    this.setState({ uploading: key });
                    await axios.put(uploadTokens[key].url, file, {
                        headers: { 'Content-Type': file.type }, onUploadProgress: progressEvent => {
                            let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ progressBar: progress });
                        }
                    }).then((res) => {
                        this.setState({ progressBar: 0 });
                    })
                    .catch((err) => console.log(err))
                }
    
            }
        }
    }

    getUploadTokens = async type => {
        if(type === 'trailer'){
            let extension = 'mp4';
            extension = this.state.file.name.slice((Math.max(0, this.state.file.name.lastIndexOf(".")) || Infinity) + 1);
            await axios.get(`${process.env.REACT_APP_API_URL}/trailer/upload-link?extension=${extension}`, {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
            }).then((res) => {
                this.setState({ uploadTokens: res.data.data })
                this.uploadToAWS('trailer');
            })
            .catch((err) => {
                console.log(err.response)
            })
        }else{
            await axios.get(`${process.env.REACT_APP_API_URL}/temp/signed-urls?type=movie&&extension=mp4`, {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
            }).then((res) => {
                this.setState({ uploadTokens: res.data.data })
                this.uploadToAWS('video');
            })
            .catch((err) => {
                console.log(err.response)
            })
        }
    }

	updateAccount = async (e) => {
        this.setState({ isLoading: true })
        e.preventDefault()
        let videoUpdate = {}
		const { videoName, videoCast, videoSynopsis, category, landscape, portrait, nameTag, video, contentProvider } = this.state
        if (videoName !== '' && videoName !== this.currentVideo.videoName) { videoUpdate.videoName = videoName }
        if (videoCast !== '' && videoCast !== this.currentVideo.videoCast) { videoUpdate.videoCast = videoCast }
        if (videoSynopsis !== '' && videoSynopsis !== this.currentVideo.videoSynopsis) { videoUpdate.videoSynopsis = videoSynopsis }
        if (category !== '' && category !== this.currentVideo.category) { videoUpdate.category = category }

        if(landscape !== '' || video !== '' || nameTag !== '' || portrait !== ''){
            
            await this.getUploadTokens('movie');
            await this.uploadToAWS('movie');

            if( landscape !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    videoThumbnailLink : this.state.uploadTokens['landscape'].Key,
                }
            }
            if( portrait !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    tallThumbNailLink : this.state.uploadTokens['portrait'].Key,
                }
            }
            if( nameTag !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    nameTagThumbNailLink : this.state.uploadTokens['nameTag'].Key,
                }
            }
            if( video !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    videoKey : this.state.uploadTokens['video'].Key,
                }
            }

        }
        if( contentProvider !== '' ){
				
			videoUpdate = {
				...videoUpdate,
				contentProvider,
			}
		}
        const countries = [];
        for(const el of this.state.resCountries){
            if(el.back === "green"){
                countries.push(el.name)
            }
        }

        if(countries[0]){
            videoUpdate = {
                ...videoUpdate,
                resCountries: countries.toString() === "" ? "valhara" : countries.toString()
            }
        }
        axios.put(`${process.env.REACT_APP_API_URL}/temp/update/temp?id=${this.props.row.id}`, videoUpdate, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
        .then((res) => {
            this.setState({ updateStatus: true,isLoading:false })
        })
        .catch(err => {
            console.log(err.response)
            this.setState({ updateStatus: false })
        })
        setTimeout(()=>this.setState({ updateStatus: null }),3000)
	}
	
	restrict = async (type,data) => {
        const { resCountries } = this.state;
        if(type === "Grant"){
            if(Array.isArray(data)){
                const newCountries = [];
                for(let i=0; i < resCountries.length;i++){
                    if(resCountries[i].region === data[0]){
                        newCountries.push({...resCountries[i],back:"green"})
                    }else{
                        newCountries.push(resCountries[i])
                    }
                }    
                this.setState({ resCountries: newCountries })    
            }else{
                const newCountries = await resCountries.map(el => {
                        if(el.name !== data){
                            return el
                        }else{
                            return {...el,back:"green"};
                        }
                    })
                this.setState({ resCountries: newCountries }) 
            }
        }else{
            if(Array.isArray(data)){
                const newCountries = [];
                for(let i=0; i < resCountries.length;i++){
                    if(resCountries[i].region === data[0]){
                        newCountries.push({...resCountries[i],back:""})
                    }else{
                        newCountries.push(resCountries[i])
                    }
                }    
                this.setState({ resCountries: newCountries })        
            }else{
                const newCountries = await resCountries.map(el => {
                        if(el.name !== data){
                            return el
                        }else{
                            return {...el,back:""};
                        }
                    })
                this.setState({ resCountries: newCountries }) 
            }
        }   
	}
	
	render() {
		const { row } = this.props;
		return (
			<>
			<div className="row px-2 hold">
			<div className="btn btn-primary col mx-1" onClick={() => this.props.approveVideo(row.id, row.videoUri)}><i style={{ color: "lightgreen" }} className="fas fa-thumbs-up"></i> Approve</div>
				<Modal
					size="modal-xl"
					style="btn btn-primary col mx-1"
					icon="large fas fa-wrench"
					text="update"
					id={row.videoName.replace(/\s/g, '').concat('edit')}
					title={this.state.videoName}>
					<div className="row grid-divider">
						<div className="col-lg-12 divider">
							<form onSubmit={this.updateAccount}>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group">
											<h6 className="text-muted">Video Name</h6>
											<input type="text" className="form-control" name="videoName" onChange={this.formChangeHandler} value={this.state.videoName} />
										</div>
									</div>

									<div className="col-lg-6">
										<div className="form-group">
											<h6 className="text-muted">Category</h6>
											<select className="form-control" onChange={this.formChangeHandler} name="category" value={this.state.category}>
												<option>Short-Film</option>
												<option>Talk-Show</option>
												<option>Documentary</option>
												<option>Action</option>
												<option>Sci-fi</option>
												<option>Horror</option>
												<option>Romance</option>
												<option>Comedy</option>
												<option>Drama</option>
												<option>Adventure</option>
												<option>Historical</option>
												<option>Musical</option>
											</select>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group">
											<h6 className="text-muted">Video Cast</h6>
											<textarea maxLength="50" style={{ resize: 'none' }} className="form-control" rows="2" onChange={this.formChangeHandler} name="videoCast" value={this.state.videoCast}></textarea>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group">
											<h6 className="text-muted">Video Synopsis</h6>
											<textarea maxLength="50" style={{ resize: 'none' }} className="form-control" rows="2" onChange={this.formChangeHandler} name="videoSynopsis" value={this.state.videoSynopsis}></textarea>
										</div>
									</div>
								</div>

								<div className="row" style={{ marginBottom: "2%" }}>
									<div className="col-lg-6">
										<div className="custom-file">
											<input type="file" accept="video/*" name="video" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile"
												disabled={row.type === 'serie' ? 'disabled' : null} />
											<label className="custom-file-label" htmlFor="customFile">Upload Video</label>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="custom-file">
											<input type="file" accept="image/*" name="nameTag" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile" required={false} />
											<label className="custom-file-label" htmlFor="customFile">320 x 320 Video Tag Name</label>
										</div>
									</div>
								</div>

								<div className="row" style={{ marginBottom: "2%" }}>
									<div className="col-lg-6">
										<div className="custom-file">
											<input type="file" accept="image/*" name="landscape" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile" required={false} />
											<label className="custom-file-label" htmlFor="customFile">1920 x 1080 Landscape Cover</label>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="custom-file">
											<input type="file" accept="image/*" name="portrait" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile" required={false} />
											<label className="custom-file-label" htmlFor="customFile">1080 x 1620 Portrait Cover</label>
										</div>
									</div>
								</div>
								<br />
								<CPS handler={(e) => this.setState({ contentProvider: e.target.value })} cp={this.state.contentProvider} />
								<br />
								<Restriction
									handler={this.restrict}
									allCountries={this.state.resCountries}
									Alignment="grid"
								/>
								<div className="row">
									<div className="col">
										<button className="btn btn-primary mr-2" type="submit">Update Video Data</button>
									</div>

									<div className="col">
										{this.state.updateStatus === true ? (
											<div className="alert alert-success alert-dismissible fade show" role="alert">
												<h6>Updated successfully!</h6>
												<button type="button" className="close" data-dismiss="alert" aria-label="Close">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
										) : (this.state.updateStatus === false ? (
											<div className="alert alert-primary alert-dismissible fade show" role="alert">
												<h6>An Error Occured</h6>
												<button type="button" className="close" data-dismiss="alert" aria-label="Close">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
										) : (null))
										}
									</div>
								</div>
							</form>

							{this.state.isLoading && (
								<div className="progress special">
									<ProgressBar
										radius={window.screen.width > 700 ? 100 : 50}
										progress={this.state.progressBar}
										strokeWidth={4}
										strokeColor="indianred"
										trackStrokeWidth={4}
										pointerRadius={8}
										pointerStrokeWidth={5}
										pointerStrokeColor="indianred"
									>
										<div className="indicator">
											<div>{this.state.progressBar}%</div>
										</div>
									</ProgressBar>
								</div>
							)}
						</div>
					</div>

				</Modal>
				<div className="btn btn-primary col mx-1" onClick={() => this.props.deleteVideo(row.id, row.videoUri)}><i style={{ color: "lightblue" }} className="fas fa-trash"></i> Delete </div>
				{row.videoUri ? (<div onClick={() => this.props.getTempVideo(row.id)} className="btn btn-secondary col mx-1" target="_blank" rel="noopener noreferrer"> <i className="fas fa-eye"></i> Video</div>) : null}
			</div>
		</>
		) 
	}
}


class Videos extends Component {

	state = {
		videos: { rows: [], columns: [] },
		video: '',
		isUploading: false,
		videoName:"",
		videoCast:"",
		videoSynopsis:"",
		category:"",
		contentProvider:"",
		videoTrailers:[],
		resCountries:[],
		updateVideoId: ''

	}

	componentDidMount() {
		this.getAllVideos()
	}

	fileChangeHandler = (e) => {
		this.setState({ [e.target.name]: e.target.files[0] })
	}

	approveVideo = (id, uri) => {
		this.setState({ isUploading: true })
		axios.post(`${process.env.REACT_APP_API_URL}/video/new?id=${id}`, null, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`, 'Content-Type': 'multipart/form-data' }
		})
			.then(response => {
				alert("Video Approved SuccessFully !")
				this.setState({ isUploading: false })
				this.getAllVideos()
			})
			.catch(error => {
				alert(error.response.data.message)
				this.setState({ isUploading: false })
			});
	}

	deleteVideo = (id, uri) => {
		this.setState({ isUploading: true })
		fetch(`${process.env.REACT_APP_API_URL}/temp?id=${id}`, {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`
			}
		}
		)
			.then(res => res.json())
			.then(response => {
				if (response.statusCode === 200) {
					alert("Video Deleted SuccessFully !")
					this.setState({ isUploading: false })
					this.getAllVideos()
				} else {
					alert(response.message)
				}
			})
			.catch(err => console.log(err))
	}

	getTempVideo = (id) => {
		axios.get(`${process.env.REACT_APP_API_URL}/temp?id=${id}`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		})
			.then((res) => {
				window.open(res.data.data.videoUri, '_blank');
			})
			.catch((err) => {
				alert(err.response);
			})
	}

	getAllVideos = () => {
		
		const actionsFormatter = (cell, row) => {
			return (
				<Action row={row} approveVideo={this.approveVideo} deleteVideo={this.deleteVideo} getTempVideo={this.getTempVideo} resCountries={this.state.resCountries} />
			)
		};
		axios.get(`${process.env.REACT_APP_API_URL}/temp/all`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		})
			.then((res) => {
				const videos = {
					rows: res.data.data, columns: [{
						dataField: 'id',
						text: 'ID',
						sort: true
					}, {
						dataField: 'videoName',
						text: 'Movie Name',
						sort: true
					},
					{
						dataField: 'clientId',
						text: 'Client Name',
						sort: true
					},
					{
						dataField: 'videoSuspensionState',
						text: 'Action',
						isDummyField: true,
						formatter: actionsFormatter
					}, {
						dataField: 'createdAt',
						text: 'Created',
						sort: true
					}]
				}
				this.setState({ videos });

			})
			.catch(err => {
				console.log(err.response)
				this.setState({ videos: { columns: null } })
			})
	}
	render() {
		return (
			<div>
				<div className="container-fluid text-body">
					<Nav page="Temporaly Videos" />
					{this.state.isUploading ? (
						<div className="alert alert-primary alert-dismissible fade show" role="alert">
							<div className="spinner-grow mr-2" role="status">
								<span className="sr-only">Loading...</span>
							</div> Uploading Video to Cinetie Servers...
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					) : null}
					<div className="row px-3">
						<DataTable rows={this.state.videos.rows} columns={this.state.videos.columns} />
					</div>

				</div>
			</div>
		)
	}
}

export default Videos
import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  Component,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import VideoUploader from "../../../components/uploaderEpisode/video";
import TagNameUploader from "../../../components/uploaderEpisode/tagName";

import PortraitUploader from "../../../components/uploaderEpisode/portrait";
import ThambnailUploader from "../../../components/uploaderEpisode/thambnailUploader.jsx";
import En_subtitleUploader from "../../../components/uploaderEpisode/En_Subtitle";
import Fr_subtitleUploader from "../../../components/uploaderEpisode/Fr_Subtitle";

const Episodes = (props) => {
  const videoRef = useRef();
  const TagNameRef = useRef();
  const ThambnailRef = useRef();
  const PortraitRef = useRef();
  const En_subtitleRef = useRef();
  const Fr_subtitleRef = useRef();

  const [state, setState] = useState({
    videoEpisode: "",
    thumb: "",
    EnSubt: "",
    FrSubt: "",
    synopsis: "",
    episodeName: "",
    episodeNumber: 1,
    episodeSeason: "1",
    getSelectedSeason: "1",
    isLoading: false,
  });

  useEffect(() => {
    return () => {};
  }, []);

  const handleVideoUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      videoEpisode: value,
    }));
  };

  const handleThumbnailUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      thumb: value,
    }));
  };

  const handleEn_SubtitleUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      EnSubt: value,
    }));
  };

  const handleFr_SubtitleUpload = (value) => {
    setState((prevState) => ({
      ...prevState,
      FrSubt: value,
    }));
  };

  const formChangeHandler = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const addEpisode = (e) => {
    e.preventDefault();

    if (state.videoEpisode == "" || state.thumb == "") {
      alert("Enter The Missing Data");
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const data = {
        videoId: props.videoId,
        video: state.videoEpisode,
        thumb: state.thumb,
        synopsis: state.synopsis,
        episodeName: state.episodeName,
        episodeNumber: state.episodeNumber,
        season: state.episodeSeason,
      };
      if (state.EnSubt !== "") data.EnSubt = state.EnSubt;
      if (state.FrSubt !== "") data.FrSubt = state.FrSubt;
      axios({
        url: `${process.env.REACT_APP_API_URL}/temp/series/new`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admintoken")}`,
          cpid: props.cpid,
          "Content-Type": "application/json",
        },
        data,
      })
        .then((response) => {
          setState({ isLoading: false });
          if (response.status === 201) {
            alert("Episode Added Successfully");

            setState((prevState) => ({
              ...prevState,
              videoEpisode: "",
              thumb: "",
              EnSubt: "",
              FrSubt: "",
              synopsis: "",
              episodeName: "",
              episodeNumber: 1,
              episodeSeason: "1",
              getSelectedSeason: "1",
            }));

            videoRef.current.reset();
            ThambnailRef.current.reset();
            Fr_subtitleRef.current.reset();
          } else {
            setState({ uploadStatus: response.message });
          }
        })
        .catch((error) => {
          setState({ isLoading: false });
          alert(error.response.data.message);
        });
    }
  };

  return (
    <form className="pb-4 pt-2" onSubmit={addEpisode}>
      <h5
        className="mt-2 mb-2"
      >
        Add Episode
      </h5>
      <div className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <h6 className="text-muted">Season</h6>
            <select
              name="episodeSeason"
              className="form-control"
              onChange={formChangeHandler}
              required
            >
              <option value="1">Season 1</option>
              <option value="2">Season 2</option>
              <option value="3">Season 3</option>
              <option value="4">Season 4</option>
              <option value="5">Season 5</option>
            </select>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group">
            <h6 className="text-muted">Number</h6>
            <input
              type="number"
              className="form-control"
              name="episodeNumber"
              onChange={formChangeHandler}
              value={state.episodeNumber}
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <h6 className="text-muted">Choose Episode</h6>
          <VideoUploader
            ref={videoRef}
            handleVideoUpload={handleVideoUpload}
            type="serie"
          />
        </div>

        <div className="col-lg-6">
          <h6 className="text-muted">Episode Thumb</h6>
          <ThambnailUploader
            ref={ThambnailRef}
            type="image"
            handleThumbnailUpload={handleThumbnailUpload}
          />
        </div>
      </div>
      <div
        className="row"
        style={{
          marginTop: "10px",
        }}
      >
        <div className="col-lg-5">
          <div className="form-group">
            <h6 className="text-muted">Episode Name</h6>
            <input
              type="text"
              className="form-control"
              name="episodeName"
              onChange={formChangeHandler}
              value={state.episodeName}
              required
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <h6 className="text-muted">Video Synopsis</h6>
            <textarea
              maxLength="250"
              style={{ resize: "none" }}
              className="form-control"
              rows="1"
              onChange={formChangeHandler}
              name="synopsis"
              value={state.synopsis}
              required
            ></textarea>
          </div>
        </div>
      </div>

      <h5
        style={{
          marginTop: "20px",
        }}
      >
        Subtitles
      </h5>
      <div className="row">
        <div className="col-lg-6">
          <p>English Subitle</p>
          <En_subtitleUploader
            ref={En_subtitleRef}
            handleEn_SubtitleUpload={handleEn_SubtitleUpload}
            type="subtitle"
          />
        </div>
        <div className="col-lg-6">
          <p> French Subtitle (optional)</p>
          <Fr_subtitleUploader
            ref={Fr_subtitleRef}
            handleFr_SubtitleUpload={handleFr_SubtitleUpload}
            type="subtitle"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <button className="mt-2 btn btn-primary mr-2 mt-4" type="submit">
            {state.isLoading ? (
              <div
                className="spinner-border spinner-border-sm mb-1 mr-3"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default Episodes;

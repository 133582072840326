import axios from 'axios';
import React, { Component } from 'react';
import Btn from '../../components/buttons';
import Modal from '../../components/modal';
import Nav from '../../components/dashboard/nav';
import DataTable from '../../components/dashboard/dataTable';

export default class Users extends Component {
    state = {
        tableData: 'getLiveUsers',
        usersInRange: '',
        startDate: '',
        endDate: '',
        numberOfScreens: '',
        monthlyPrice: '',
        yearlyPrice: '',
        type: '',
        id: null,
        rows: [],
        columns: [],
        plans: [],
        isLoading: false,
    }

    componentWillMount() {
        this.getLiveUsers()
    }

    getLiveUsers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/users/live`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
            .then((res) => {
                this.setState({
                    rows: res.data.data.data,
                    columns: [{
                        dataField: 'id',
                        text: 'User ID',
                        sort: true
                    }, {
                        dataField: 'email',
                        text: 'Email',
                        sort: true
                    }, {
                        dataField: 'date',
                        text: 'Date',
                        sort: true
                    },
                    {
                        dataField: 'timeIn',
                        text: 'Time In',
                        sort: true
                    }, {
                        dataField: 'state',
                        text: 'Current State',
                        sort: true
                    }]
                });
            })
            .catch(err => console.log(err.response))
    };

    getUsersInRange = () => {
        let startDate = this.state.startDate
        let endDate = this.state.endDate
        const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
        startDate = startDate.replace(pattern, '$2-$3-$1');
        endDate = endDate.replace(pattern, '$2-$3-$1');
        axios.get(`${process.env.REACT_APP_API_URL}/auth/users?start=${startDate}&end=${endDate}`, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`
            }
        })
            .then((res) => {
                console.log(res.data.data.data)
                this.setState({
                    rows: res.data.data.data, columns: [
                        {
                            dataField: 'id',
                            text: 'User ID',
                            sort: true
                        }, {
                            dataField: 'email',
                            text: 'Email',
                            sort: true
                        }, {
                            dataField: 'endDate',
                            text: 'End Date',
                            sort: true
                        },
                        {
                            dataField: 'plan',
                            text: 'Plan',
                            sort: true
                        }, {
                            dataField: 'state',
                            text: 'Current State',
                            sort: true
                        }
                    ],
                })
                console.log(this.state.tableData)
            })
            .catch(err => console.log(err.response))
    }

    handleFormChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    checkboxHandler = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    UpdatePlan = (e) => {
        this.setState({ isLoading: true })
        e.preventDefault()
        const { numberOfScreens, monthlyPrice, yearlyPrice, type, id, selectedPlan } = this.state
        if (!id && !selectedPlan) {
            return axios.post(`${process.env.REACT_APP_API_URL}/plan/new`, {
                numberOfScreens,
                monthlyPrice,
                yearlyPrice,
                type
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('admintoken')}` }
            })
                .then((res) => {
                    alert('Plan Added Successfully')
                    this.setState({
                        numberOfScreens: '',
                        monthlyPrice: '',
                        yearlyPrice: '',
                        type: '',
                        id: '',
                        isLoading: false,
                    })
                    this.getPlans()
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    alert('An Error Occured')
                    console.log(err.response)
                })
        }

        return axios.put(`${process.env.REACT_APP_API_URL}/plan/${id}`, {
            numberOfScreens,
            monthlyPrice,
            yearlyPrice,
            type
        }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('admintoken')}` }
        })
            .then((res) => {
                alert('Plan Updated Successfully')
                this.setState({
                    numberOfScreens: '',
                    monthlyPrice: '',
                    yearlyPrice: '',
                    type: '',
                    id: '',
                    isLoading: false,
                })
                this.getPlans()

            })
            .catch(err => {
                this.setState({ isLoading: false })
                alert('An Error Occured')
                console.log(err.response)
            })
    }

    getPlans = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/plan`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('admintoken')}` }
        })
            .then((res) => {
                console.log(res.data.data)
                this.setState({ plans: res.data.data })

            })
            .catch(err => console.log(err.response))
    }


    selectPlan = (e) => {
        this.setState({ selectedPlan: e.target.value })
        const { plans } = this.state
        for (let i = 0; i < plans.length; i++) {
            if (plans[i].type === e.target.value) {
                console.log(plans[i].type, 'Selected Plan')
                const { numberOfScreens, monthlyPrice, yearlyPrice, type, id } = plans[i]
                this.setState({
                    numberOfScreens,
                    monthlyPrice,
                    yearlyPrice,
                    type,
                    id
                })
            }
        }
    }

    render() {
        return (
            <div>
                <div className="container-fluid text-body">
                    <Nav page="Users" />
                    <div className="px-2">
                        <Modal
                            onClick={() => this.getPlans()}
                            style="btn-primary btn-sm" text="Modify Plan" title="Update Pricing Plans">

                            <form className="pb-4 px-3" onSubmit={this.UpdatePlan}>
                                <div className="form-group">
                                    <label>Select Plan</label>
                                    <select className="form-control" onChange={this.selectPlan} value={this.state.type}>
                                        <option value="plan">--</option>
                                        {this.state.plans.map(option => {
                                            return (
                                                <option value={option.type} key={option.id}>{option.type}</option>
                                            )
                                        })
                                        }

                                    </select>
                                </div>
                                <div className="row py-2">
                                    <div className="col">
                                        <label>Monthly Price</label>
                                        <input name="monthlyPrice" value={this.state.monthlyPrice} onChange={this.handleFormChange} type="number" className="form-control" placeholder="Monthly Price" />
                                    </div>
                                    <div className="col">
                                        <label>Yearly Price</label>
                                        <input name="yearlyPrice" value={this.state.yearlyPrice} onChange={this.handleFormChange} type="number" className="form-control" placeholder="Yearly Price" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>Number of Screens</label>
                                        <input name="numberOfScreens" value={this.state.numberOfScreens} onChange={this.handleFormChange} type="number" className="form-control" placeholder="Number Of Screens" />
                                    </div>
                                    <div className="col">
                                        <label>Plan Type</label>
                                        <input name="type" value={this.state.type} onChange={this.handleFormChange} type="text" className="form-control" placeholder="Type" />
                                    </div>
                                </div>


                                <div className="pt-3">
                                    <button disabled={this.state.selectedPlan ? false : true} className="btn btn-primary mr-2" type="submit">
                                        {this.state.isLoading && this.state.selectedPlan ? (
                                            <div className="spinner-border spinner-border-sm mr-2" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (null)
                                        }
                                        Update
                                    </button>

                                    <button disabled={this.state.selectedPlan ? true : false} className="btn btn-primary mr-2" type="submit">
                                        {this.state.isLoading && !this.state.selectedPlan ? (
                                            <div className="spinner-border spinner-border-sm mr-2" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (null)
                                        }
                                        Create New Plan
                                    </button>
                                </div>
                            </form>

                        </Modal>
                    </div>

                    <div className="pt-3 px-3">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a onClick={() => { this.setState({ tableData: 'getLiveUsers', rows: [] }); this.getLiveUsers() }} className="nav-link active" id="live-tab" data-toggle="tab" href="#live" role="tab" aria-controls="live" aria-selected="true">Live Users</a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { this.setState({ tableData: 'getUsersInRange', rows: [] }) }} className="nav-link" id="range-tab" data-toggle="tab" href="#range" role="tab" aria-controls="range" aria-selected="false">Users In Range</a>
                            </li>
                        </ul>

                    </div>


                    <div className="row pt-3 px-4">
                        {this.state.tableData === "getUsersInRange" ? (
                            <div className="row px-4">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label className="text-muted">Start Date</label>
                                        <input type="date" className="form-control form-control-sm" name="startDate" value={this.state.startDate} onChange={this.handleFormChange} />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label className="text-muted">End Date</label>
                                        <input type="date" className="form-control form-control-sm" name="endDate" value={this.state.endDate} onChange={this.handleFormChange} />
                                    </div>
                                </div>
                                <div className="col-lg-2 pt-4">
                                    <Btn style="btn-primary" text="Submit" onClick={this.getUsersInRange} />
                                </div>
                            </div>
                        ) : (null)}

                        <DataTable rows={this.state.rows} columns={this.state.columns} />

                    </div>

                </div>


            </div >
        )
    }
}

/* eslint-disable no-console */
import { DISPLAY_VIDEO_VIEWS, DISPLAY_CP_VIDEO_EARNINGS, DISPLAY_CP_EARNINGS, DISPLAY_ADMIN_SALES } from '../actions/actionType';

const initState = {
    barChartData: [],
    total: null
};

const barChartReducer = (state = initState, action) => {
    switch (action.type) {
        case DISPLAY_VIDEO_VIEWS:
            return {
                ...state,
                barChartData: action.payload || null,
                total: action.total
            };
        case DISPLAY_CP_VIDEO_EARNINGS:
            return {
                ...state,
                barChartData: action.payload || null,
                total: action.total
            };
        case DISPLAY_CP_EARNINGS:
            return {
                ...state,
                barChartData: action.payload || null,
                total: action.total
            };
        case DISPLAY_ADMIN_SALES:
            return {
                ...state,
                barChartData: action.payload || null,
                total: action.total
            };
        default:
            return state;
    }
};

export default barChartReducer;

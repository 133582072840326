import React from 'react'

const Btn = (props) => {
    let btnSize = '';
    if (props.size === 'sm') { btnSize = 'btn-small' }
    
    // else if (props.size == null) { btnSize = 'font-weight-bold' }
    return (
        <button
            className={`btn ${props.style} 
        my-1 mx-1 
        ${btnSize}`}
            name={props.name}
            onClick={props.onClick}
            disabled={props.disabled}
            data-toggle={props.type === "modalBtn" ? ("modal") : null} data-target={props.type === "modalBtn" ? ("#exampleModal") : null}
        >
            {props.icon != null ? (<i className={`fa ${props.icon}`}></i>) : (null)} {props.text}
        </button>
    )
}
export default Btn;

/* eslint-disable no-console */
import { DISPLAY_VIDEO_STATISTICS, DISPLAY_All_VIDEOS, DISPLAY_TOTAL_EARNINGS, DISPLAY_ACTIVE_EARNINGS } from '../actions/actionType';

const initState = {
  Videos: {movies:[],series:[]},
  videoStats: [],
  totalEarnings: '',
  activeEarnings: ''
};

const statisticsReducer = (state = initState, action) => {
  switch (action.type) {
    case DISPLAY_TOTAL_EARNINGS:
      return {
        ...state,
        totalEarnings:  action.payload
      };
      case DISPLAY_ACTIVE_EARNINGS:
      return {
        ...state,
        activeEarnings: action.payload
      };
    case DISPLAY_VIDEO_STATISTICS:
      return {
        ...state,
        videoStats: action.payload,
      };
    case DISPLAY_All_VIDEOS:
      return {
        ...state,
        Videos: {
          movies: action.payload.filter(el => el.type === "movie"),
          series: action.payload.filter(el => el.type === "serie")
        },
      };
    case 'ClearAll':
      return state={
        ...state,
        Videos: {movies:[],series:[]},
      }
    case 'Erase Movie':
      return {
        ...state,
        videos:{
          ...state.Videos.series,
          movies: state.Videos.movies.filter(el => el.id !== action.payload )
        }
      }
    default:
      return state;
  }
};

export default statisticsReducer;

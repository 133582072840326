/* eslint-disable no-console */
import { DISPLAY_CHECKOUT_HISTORY, DISPLAY_LIVE_USERS, DISPLAY_ALL_CP } from '../actions/actionType';

const initState = {
  rows: [],
  columns: []
};

const dataTableReducer = (state = initState, action) => {
  switch (action.type) {
    case DISPLAY_CHECKOUT_HISTORY:
      return {
        ...state,
        rows: action.payload,
        columns: action.columns
      };
    case DISPLAY_LIVE_USERS:
      return {
        ...state,
        rows: action.payload,
        columns: action.columns
      };
      case DISPLAY_ALL_CP:
      return {
        ...state,
        rows: action.payload,
        columns: action.columns
      };
    default:
      return state;
  }
};

export default dataTableReducer;

import CP from './cp'
import React from 'react';
import Sales from './sales'
import Users from './users'
import Videos from './features'
import Payments from './payments'
import PostVideo from './postVideo'
import PostSerie from './postSerie'
import AllMovies from './allMovies';
import AllSeries from './AllSeries';
import TempMovies from './tempMovies'
import TempSeries from './tempSeries'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cinetieLogo from '../../assets/images/logo2.png'


class Dashboard extends React.Component {

    state = {
        page: 'users'
    }

    selectPage = page => {
        this.setState({ page })
    }

    render() {
        const { page } = this.state
        return (
            <React.Fragment>
                <div className="whiteBg">
                    <nav className="navbar navbar-expand navbar-dark bg-dark static-top">
                        <img src={cinetieLogo} alt="logo" />
                        <div className="ml-auto mr-0 mr-md-3 my-2 my-md-0"></div>

                        <ul className="navbar-nav ml-auto ml-md-0">
                            <li className="nav-item dropdown no-arrow">
                                <Link className="nav-link dropdown-toggle" to="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-lg fa-user-circle fa-fw"></i>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                    <Link to="#" className="dropdown-item" onClick={() => { localStorage.clear(); window.open('/signin', '_self') }}>Logout</Link>
                                </div>
                            </li>
                        </ul>

                    </nav>

                    <div id="wrapper">

                        <ul className="sidebar bg-dark navbar-nav font-weight-bold">
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('users')} style={{color: this.state.page === "users" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-user-o fa-dashboard"></i>
                                    <span> Users</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('contentProviders')} style={{color: this.state.page === "contentProviders" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-user-circle-o fa-video-camera"></i>
                                    <span> Content Providers</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('feature')} style={{color: this.state.page === "feature" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-file-video-o"></i>
                                    <span> Features</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('TempMovies')} style={{color: this.state.page === "TempMovies" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-video-camera fa-video-camera"></i>
                                    <span> Temp Movies</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('TempSeries')} style={{color: this.state.page === "TempSeries" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-video-camera fa-tv"></i>
                                    <span> Temp Episodes</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('payments')} style={{color: this.state.page === "payments" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-fw fa-credit-card"></i>
                                    <span> Transactions</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('sales')} style={{color: this.state.page === "sales" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-fw fa-dollar"></i>
                                    <span> Sales</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('Post')} style={{color: this.state.page === "Post" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-video-camera"></i>
                                    <span> Post Video</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('PostSerie')} style={{color: this.state.page === "PostSerie" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-video-camera"></i>
                                    <span> Post Serie</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('AllMovies')} style={{color: this.state.page === "AllMovies" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-video-camera fa-video-camera"></i>
                                    <span> All Movies</span>
                                </p>
                            </li>
                            <li className="nav-item  mt-3">
                                <p onClick={() => this.selectPage('AllSeries')} style={{color: this.state.page === "AllSeries" ? "crimson" : "white"}} className="nav-link">
                                    <i className="fa fa-video-camera fa-tv"></i>
                                    <span> All Series</span>
                                </p>
                            </li>
                        </ul>

                        <div id="content-wrapper">
                            <div className="container-fluid pb-4">
                                {page === 'feature' && <Videos />}
                                {page === 'TempMovies' && <TempMovies />}
                                {page === 'TempSeries' && <TempSeries />}
                                {page === 'users' && <Users />}
                                {page === 'contentProviders' && <CP />}
                                {page === 'payments' && <Payments />}
                                {page === 'sales' && <Sales />}
                                {page === 'Post' && <PostVideo />}
                                {page === 'PostSerie' && <PostSerie />}
                                {page === 'AllMovies' && <AllMovies />}
                                {page === 'AllSeries' && <AllSeries />}
                            </div>
                            <footer className="sticky-footer">
                                <div className="container my-auto">
                                    <div className="copyright text-center my-auto text-dark">
                                        <span>Copyright © Cinetie 2020</span>
                                    </div>
                                </div>
                            </footer>

                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ cp }) => {
    return {
        cpStats: cp.cpStats,
    }
};
export default connect(mapStateToProps)(Dashboard)
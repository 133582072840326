/* eslint-disable react/style-prop-object */
import React, { Component } from 'react';
import Nav from '../../components/dashboard/nav';
import Charts from '../../components/dashboard/barChart';


export default class payments extends Component {

	render() {
		return (
			<div>
				<div className="container-fluid text-body">
					<Nav page="Sales" />

					<div className="row">
						<Charts type="sales" />
					</div>
				</div>
			</div>
		)
	}
}

/* eslint-disable react/style-prop-object */
import axios from 'axios';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Card from '../../components/card';
import Btn from '../../components/buttons';
import Modal from '../../components/modal';
import CPS from './helpers/contentProviders';
import Categories from './helpers/categories';
import '../../styles/components/progress.scss';
import Nav from '../../components/dashboard/nav';
import Restriction from './helpers/restrictions';
import ProgressBar from 'react-customizable-progressbar';
import BarChart from '../../components/dashboard/barChart';
import { Loader } from '../../components/video/videoLoader';
import { getALLVideos,clearALLVideos } from '../../actions/cpActions';

class Videos extends Component {

    state = {
        videoStatistics: 'views',
        barChartData: 'views',
        videoTrailers: [],
        updateVideoId: '',
        updateStatus: null,
        deleteStatus: null,
        isLoading: false,
        videoName: '',
        videoCast: '',
        videoSynopsis: '',
        category: '',
        file: '',
        EnSubt:'',
        FrSubt:'',
        HasEnSubt:'',
        HasFrSubt:'',
        HasCp:'',
        uploadTokens: null,
        progressBar: 1,
        progressOwner:"",
        landscape: '',
        nameTag: '',
        portrait: '',
        video: '',
        resCountries:[],
        contentProvider:'',
        searchQuery:'',
        licenseEndDate: null,
        copy:{id:"",status:false},
    }
    
    currentVideo = {}

    componentDidMount() {
        if(!this.props.cpVideos[0])
        this.props.getALLVideos()
    }

    getUpdateVideo = (id) => {
        this.setState({ updateVideoId: id })
        this.setState({ videoName:"", videoCast:"", videoSynopsis:"", category:"", HasEnSubt:"", HasFrSubt:"", contentProvider:"", videoTrailers:[], resCountries:[] })
        axios.get(`${process.env.REACT_APP_API_URL}/video/cp/unique?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
        .then((res) => {
            const { EnSubt, FrSubt, videoName, videoCast, videoSynopsis, category, videoClientId, resCountries, licenseEndDate } = res.data.data;
            fetch(`${process.env.REACT_APP_API_URL}/cp/all`,{
                method:"GET",
                headers:{ 
                    Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`
                },})
            .then(res => res.json())
            .then(payload =>{
                const { data } = payload;
                let cp ;
                
                for(const el of data){
                    if(el.clientId === videoClientId){
                        cp = el.ClientId
                    }
                }

                this.setState({ videoName, licenseEndDate, videoCast, HasEnSubt:EnSubt, HasFrSubt:FrSubt, HasCp: cp !== undefined ? cp : "Choose", videoSynopsis, category, contentProvider : cp !== undefined ? cp : "Choose" })
            })
            .catch(err => console.log(err));
                ///
            fetch('https://restcountries.eu/rest/v2/all?fields=name;flag;region')
            .then(res => res.json())
            .then(async data => {
                if(resCountries !== undefined && resCountries !== null && resCountries !== ""){
                    const restrictions = resCountries.split(",");
                    const arr = [];
                    for(const country of data){
                        const { name } = country;
                        const exist = await restrictions.find(el => el === name)
                        if(exist){
                            arr.push({ ...country, back:"green" })
                        }else{
                            arr.push({ ...country, back:"" })
                        }
                    }
                    this.setState({ resCountries : arr })
                }else{
                    this.setState({ resCountries : data })
                }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err.message))
    }

    formChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    fileChangeHandler = (e) => {
        const file = e.target.files[0];
        this.setState({ file })
    }

    complexChangeHandler = e => {
        if(e.target.files[0] !== undefined)
        this.setState({ [e.target.name]: e.target.files[0] })
    }

    updateAccount = async (e) => {
        this.setState({ isLoading: true })
        e.preventDefault()
        let videoUpdate = {}
        const {   
            HasCp,
            EnSubt,
            FrSubt,
            video, 
            nameTag,
            portrait,
            category, 
            landscape, 
            videoName, 
            videoCast, 
            videoSynopsis, 
            licenseEndDate,
            contentProvider,
        } = this.state;
        if(licenseEndDate){ videoUpdate.licenseEndDate = licenseEndDate }
        if (videoName !== '' && videoName !== this.currentVideo.videoName) { videoUpdate.videoName = videoName }
        if (videoCast !== '' && videoCast !== this.currentVideo.videoCast) { videoUpdate.videoCast = videoCast }
        if (videoSynopsis !== '' && videoSynopsis !== this.currentVideo.videoSynopsis) { videoUpdate.videoSynopsis = videoSynopsis }
        if (category !== '' && category !== this.currentVideo.category) { videoUpdate.category = category }

        if(landscape !== '' || EnSubt !== '' || FrSubt !== '' || video !== '' || nameTag !== '' || portrait !== ''){
            
            await this.getUploadTokens('movie');
            await this.uploadToAWS('movie');

            if( landscape !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    videoThumbnailLink : this.state.uploadTokens['landscape'].Key,
                }
            }
            if( portrait !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    tallThumbNailLink : this.state.uploadTokens['portrait'].Key,
                }
            }
            if( nameTag !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    nameTagThumbNailLink : this.state.uploadTokens['nameTag'].Key,
                }
            }
            if( video !== '' ){
                videoUpdate = {
                    ...videoUpdate,
                    videoLink : this.state.uploadTokens['video'].Key,
                }
            }
            if( contentProvider !== '' && contentProvider !== HasCp ){
                videoUpdate = {
                    ...videoUpdate,
                    videoClientId:contentProvider,
                }
            }
            if( EnSubt !== '' && EnSubt !== null){
                videoUpdate = {
                    ...videoUpdate,
                    EnSubt: this.state.uploadTokens["EnSubt"].Key,
                }
            }
            if( FrSubt !== '' && FrSubt !== null){
                videoUpdate = {
                    ...videoUpdate,
                    FrSubt: this.state.uploadTokens["FrSubt"].Key,
                }
            }
        }
        
        const countries = [];
        for(const el of this.state.resCountries){
            if(el.back === "green"){
                countries.push(el.name)
            }
        }

        if(countries[0]){
            videoUpdate = {
                ...videoUpdate,
                resCountries: countries.toString() === "" ? "valhara" : countries.toString()
            }
        }

        axios.put(`${process.env.REACT_APP_API_URL}/video?id=${this.state.updateVideoId}`, videoUpdate, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
        .then((res) => {
            this.setState({ updateStatus: true,isLoading:false })
        })
        .catch(err => {
            console.log(err.response)
            this.setState({ updateStatus: false })
        })
        setTimeout(()=>this.setState({ updateStatus: null }),3000)
    }

    getTrailers = (id) => {
        this.setState({ videoTrailers:[] })
        axios.get(`${process.env.REACT_APP_API_URL}/trailer/sort/video?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
        .then((res) => {
            this.setState({ videoTrailers: res.data.data })
        })
        .catch((err) => {
            if(err.response.status === 404){
                this.setState({ videoTrailers : null })
            }else{
                console.log(err.response)
            }
        })
    }

    deleteTrailer = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/trailer/delete?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
        .then((res) => {
            alert(res.data.message)
        })
        .catch((err) => console.log(err.response))
    }

    finishUpload = () => {
        var trailerData = new FormData();
        trailerData.append('videoId', this.state.updateVideoId);
        trailerData.append('trailer', this.state.uploadTokens.Key);

        axios({
            url: `${process.env.REACT_APP_API_URL}/trailer/new`, method: 'POST',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`, 'Content-Type': 'multipart/form-data' },
            data: trailerData
        })
            .then(response => {
                this.setState({ isLoading: false })
                if (response.status === 201) {
                    alert("Trailer Uploaded Successfully")
                }
                else {
                    this.setState({ uploadStatus: response.message })
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ isLoading: false })
                alert(error.response.data.message)
            });
    }

    uploadToAWS = async mode => {
        if(mode === 'trailer'){
            const file = this.state.file;
            await axios.put(this.state.uploadTokens.url, file, {
                headers: { 'Content-Type': file.type }, onUploadProgress: progressEvent => {
                    let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({ progressBar: progress });
                }
            }).then((res) => {
                this.setState({ progressBar: 1 });
                this.finishUpload(mode);
            })
            .catch((err) => {
                this.setState({ isLoading: false })
                alert(err.response);
            })
        }else{
            const { uploadTokens } = this.state;

            for (let key in uploadTokens) {
                const file = this.state[key];
                if (uploadTokens[key] && file !== '' && file !== null) {
                    this.setState({ uploading: key,progressOwner:key });
                    await axios.put(uploadTokens[key].url, file, {
                        headers: { 'Content-Type': file.type }, onUploadProgress: progressEvent => {
                            let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({ progressBar: progress });
                        }
                    }).then((res) => {
                        this.setState({ progressBar: 0 });
                    })
                    .catch((err) => console.log(err))
                }
    
            }
        }
    }

    getUploadTokens = async type => {
        if(type === 'trailer'){
            let extension = 'mp4';
            extension = this.state.file.name.slice((Math.max(0, this.state.file.name.lastIndexOf(".")) || Infinity) + 1);
            await axios.get(`${process.env.REACT_APP_API_URL}/trailer/upload-link?extension=${extension}`, {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
            }).then((res) => {
                this.setState({ uploadTokens: res.data.data })
                this.uploadToAWS('trailer');
            })
            .catch((err) => {
                console.log(err.response)
            })
        }else{
            await axios.get(`${process.env.REACT_APP_API_URL}/temp/signed-urls?type=movie&&extension=mp4`, {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
            }).then((res) => {
                this.setState({ uploadTokens: res.data.data })
                this.uploadToAWS('video');
            })
            .catch((err) => {
                console.log(err.response)
            })
        }
    }

    addTrailer = (e) => {
        e.preventDefault()
        this.setState({ isLoading: true })
        this.getUploadTokens('trailer');
    }

    restrict = async (type,data) => {
        const { resCountries } = this.state;

        if(type === "Grant"){
            if(Array.isArray(data)){
                const newCountries = [];
                for(let i=0; i < resCountries.length;i++){
                    if(resCountries[i].region === data[0]){
                        newCountries.push({...resCountries[i],back:"green"})
                    }else{
                        newCountries.push(resCountries[i])
                    }
                }    
                this.setState({ resCountries: newCountries })    
            }else{
                const newCountries = await resCountries.map(el => {
                        if(el.name !== data){
                            return el
                        }else{
                            return {...el,back:"green"};
                        }
                    })
                this.setState({ resCountries: newCountries }) 
            }
        }else{
            if(Array.isArray(data)){
                const newCountries = [];
                for(let i=0; i < resCountries.length;i++){
                    if(resCountries[i].region === data[0]){
                        newCountries.push({...resCountries[i],back:""})
                    }else{
                        newCountries.push(resCountries[i])
                    }
                }    
                this.setState({ resCountries: newCountries })        
            }else{
                const newCountries = await resCountries.map(el => {
                        if(el.name !== data){
                            return el
                        }else{
                            return {...el,back:""};
                        }
                    })
                this.setState({ resCountries: newCountries }) 
            }
        }   
    }

    handlerCopy = (id) => {
        const el = document.createElement("input");
        el.value = 'https://www.cinetie.com/video/'+id;
        document.body.appendChild(el);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(el);
        this.setState({ copy:{ id, status:true } })
        setTimeout(()=> this.setState({ copy:{ id, status:false} }) , 1000)
    }


    render() {
        const { cpVideos } = this.props;
        const { searchQuery,copy } = this.state;
        const today = new Date();
        const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? '0' : ''}${today.getMonth() + 1}-${today.getDate() < 10 ? '0' : ''}${today.getDate()}`;

        const deleteVideo = (id) => {
            axios.delete(`${process.env.REACT_APP_API_URL}/video?id=${id}`, {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
            })
            .then((res) => {
                this.setState({ deleteStatus: true });
                setTimeout(()=>this.setState({ deleteStatus: null }),2000);
            })
            .catch(err => {
                this.setState({ deleteStatus: "File Was Already Deleted" })
                setTimeout(()=>this.setState({ deleteStatus: null }),2000)
            })
        };

        return (
            <div className="hold">
				<div className="container-fluid text-body">
					<Nav page="All Videos" />
                    <div className="content-header">
                        <div className="input-field">
                            <i className="fas fa-robot"></i>
                            <input placeholder="Search Engine..." value={searchQuery} onChange={(e) => this.setState({ searchQuery: e.target.value })} />
                            { 
                                searchQuery === "" ? 
                                    <i className="fas fa-search"></i> : 
                                    <i onClick={()=> 
                                        this.setState({ searchQuery:"" })
                                        } 
                                        className="fas fa-times"></i>
                            }
                        </div>
                        <div className="refresh" onClick={()=> { 
                                this.props.clearALLVideos(); 
                                this.props.getALLVideos();
                                }} > 
                            <i className="fas fa-sync" ></i>
                        </div>
                    </div>
                    <div className="parent">
                        {cpVideos === null ? (
                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                No Data Present
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : cpVideos.length === 0
                                ? <Loader/>
                                : (cpVideos.map(video => {
                                    const { videoSuspensionState,videoName } = video;
                                    if(!videoSuspensionState&& videoName.toLowerCase().includes(searchQuery.toLowerCase()))
                                    return  (
                                    <Card size="3" key={video.id} img={video.videoThumbnailLink} title={video.videoName} licence={video.licenseEndDate}>
                                        <div className="row" style={{display:"flex",justifyContent:"flex-start",paddingLeft:"1em",alignItems:"center"}}>
                                                
                                                <Modal
                                                    embed={{borderRadius:"20px",margin:"2%"}}
                                                    style="btn-primary btn-sm" icon="fas fa-chart-line"
                                                    id={video.videoName.replace(/\s/g, '')}
                                                    title={video.videoName}
                                                    footer={<p>Total Count : {video.videoWatchCount}</p>}>
                                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a onClick={() => this.setState({ barChartData: 'views' })} className="nav-link active" id="views-tab" data-toggle="tab" href="#views" role="tab" aria-controls="views" aria-selected="true">Views</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a onClick={() => this.setState({ barChartData: 'earnings' })} className="nav-link" id="earnings-tab" data-toggle="tab" href="#earnings" role="tab" aria-controls="earnings" aria-selected="false">Earnings</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        <div className="tab-pane fade show active" id="views" role="tabpanel" aria-labelledby="views-tab">
                                                            {this.state.barChartData === 'views' ? (
                                                                <BarChart id={video.videoId} type="views" />
                                                            ) : null}
                                                        </div>
                                                        <div className="tab-pane fade" id="earnings" role="tabpanel" aria-labelledby="earnings-tab">
                                                            {this.state.barChartData === 'earnings' ? (
                                                                <BarChart id={video.videoId} type="cpVideoEarnings" />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Modal>

                                                <Modal
                                                    onClick={() => this.getUpdateVideo(video.videoId)}
                                                    size="modal-xl"
                                                    style="btn-primary btn-sm"
                                                    icon="fas fa-binoculars"
                                                    embed={{borderRadius:"20px",margin:"2%"}}
                                                    id={video.videoName.replace(/\s/g, '').concat('edit')}
                                                    title={video.videoName}>

                                                    <div className="row grid-divider">
                                                        <div className="col-lg-7 divider">
                                                            <form onSubmit={this.updateAccount}>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <h6 className="text-muted">Video Name</h6>
                                                                            <input type="text" className="form-control" name="videoName" onChange={this.formChangeHandler} value={this.state.videoName} />
                                                                        </div>
                                                                    </div>
                                                                <CPS width={6} handler={ contentProvider => this.setState({ contentProvider })} cp={this.state.contentProvider} />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <h6 className="text-muted">Video Cast</h6>
                                                                            <textarea maxLength="50" style={{ resize: 'none' }} className="form-control" rows="2" onChange={this.formChangeHandler} name="videoCast" value={this.state.videoCast}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <h6 className="text-muted">Video Synopsis</h6>
                                                                            <textarea maxLength="50" style={{ resize: 'none' }} className="form-control" rows="2" onChange={this.formChangeHandler} name="videoSynopsis" value={this.state.videoSynopsis}></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="row" style={{marginBottom:"2%"}}> 
                                                                    <div className="col-lg-6">
                                                                        <div className="custom-file">
                                                                            <input type="file" accept="video/*" name="video" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile"
                                                                                disabled={this.state.type === 'serie' ? 'disabled' : null} />
                                                                            <label className="custom-file-label" htmlFor="customFile">Upload Video</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="custom-file" >
                                                                            <input type="file" accept="image/*" name="nameTag" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile" required={false} />
                                                                            <label className="custom-file-label" htmlFor="customFile">320 x 320 Video Tag Name</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row" style={{marginBottom:"2%"}}>
                                                                    <div className="col-lg-6">
                                                                        <div className="custom-file">
                                                                            <input type="file" accept="image/*" name="landscape" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile" required={false} />
                                                                            <label className="custom-file-label" htmlFor="customFile">1920 x 1080 Landscape Cover</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="custom-file">
                                                                            <input type="file" accept="image/*" name="portrait" onChange={this.complexChangeHandler} className="custom-file-input" id="customFile" required={false} />
                                                                            <label className="custom-file-label" htmlFor="customFile">1080 x 1620 Portrait Cover</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <h5 className="text-muted">Subtitles</h5>
                                                                <div className="row mb-3">
                                                                    <div className="col-lg-6">
                                                                        { this.state.HasEnSubt !== "" && this.state.HasEnSubt !== null && <h6 style={{color:"green"}}>uploaded Already</h6>}
                                                                        <div className="custom-file">
                                                                            <input type="file" name="EnSubt" onChange={this.complexChangeHandler} accept=".srt" className="custom-file-input" id="customFile" required={false} />
                                                                            <label className="custom-file-label" htmlFor="customFile">English Subitle </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        { this.state.HasFrSubt !== "" && this.state.HasFrSubt !== null &&  <h6 style={{color:"green"}}>uploaded Already</h6>}
                                                                        <div className="custom-file">
                                                                            <input type="file" name="FrSubt" onChange={this.complexChangeHandler} accept=".srt" className="custom-file-input" id="customFile" required={false} />
                                                                            <label className="custom-file-label" htmlFor="customFile">French Subtitle (optional)</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <h5 className="text-muted">Categories</h5>
                                                                <Categories state={this.state.category} width={5} set={ category => this.setState({ category })}/>
                                                                <br/>
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="form-group">
                                                                            <h5 className="text-muted">License end date</h5>
                                                                            <input min={todayDate} type="date" name="licenseEndDate" onChange={this.formChangeHandler} className="form-control" rows="2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <Restriction 
                                                                    handler={this.restrict} 
                                                                    allCountries={this.state.resCountries}
                                                                    Alignment="grid"
                                                                    />
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <button className="btn btn-primary mr-2" type="submit">Update Video Data</button>
                                                                    </div>

                                                                    <div className="col">
                                                                        {this.state.updateStatus === true ? (
                                                                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                                                <h6>Updated successfully!</h6>
                                                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>   
                                                                            </div>
                                                                        ) : (this.state.updateStatus === false ? (
                                                                            <div className="alert alert-primary alert-dismissible fade show" role="alert">
                                                                                <h6>An Error Occured</h6>
                                                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                        ) : (null))
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </form>
                                                            <hr />

                                                            <form className="trailer-handler" onSubmit={this.addTrailer}>
                                                                <h5>Upload a trailer</h5>
                                                                <div>
                                                                    <input className="trailer-input" onChange={this.fileChangeHandler} type="file" accept="video/*" required />
                                                                    <button onClick={() => { this.setState({ updateVideoId: video.videoId }); }} >
                                                                        Upload <i className="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </form>
                                                            {this.state.isLoading && (
                                                                <div className="progress">
                                                                <h3>{this.state.progressOwner}</h3>
                                                                    <ProgressBar
                                                                        radius={ window.screen.width > 700 ? 100 : 50 }
                                                                        progress={this.state.progressBar}
                                                                        strokeWidth={4}
                                                                        strokeColor="indianred"
                                                                        trackStrokeWidth={4}
                                                                        pointerRadius={8}
                                                                        pointerStrokeWidth={5}
                                                                        pointerStrokeColor="indianred"
                                                                    >
                                                                        <div className="indicator">
                                                                            <div>{this.state.progressBar}%</div>
                                                                        </div>
                                                                    </ProgressBar>
                                                                </div>
                                                            ) }

                                                        </div>
                                                        <div className="col-lg-5 divider">
                                                            <h5>Trailers</h5>
                                                            <Btn onClick={() => this.getTrailers(video.videoId)} style="btn-primary btn-sm mb-2" text="Get Video Trailers" />
                                                            {this.state.videoTrailers !== null &&  this.state.videoTrailers[0] && (
                                                                this.state.videoTrailers.map(trailer => (
                                                                    <div key={trailer.id}>
                                                                        <div className="row pr-3">
                                                                            <div className="col pt-2">
                                                                                <a href={trailer.trailerLink} target="_blank" rel="noopener noreferrer">Trailer {trailer.id}</a>
                                                                            </div>
                                                                            <div className="col text-right">
                                                                                <Btn onClick={async () => {
                                                                                    await this.deleteTrailer(trailer.id);
                                                                                    this.getTrailers(video.videoId);
                                                                                    }} style="btn-primary btn-sm" icon="fa-trash" />
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                ))
                                                            ) }
                                                            {this.state.videoTrailers === null && <h4 style={{color:"grey"}}>no trailer, yet.</h4>}
                                                        </div>
                                                    </div>

                                                </Modal>

                                                <Modal
                                                    size="small"
                                                    style="btn-primary btn-sm" 
                                                    icon="fas fa-trash"
                                                    embed={{borderRadius:"20px",margin:"2%"}}
                                                    id={video.videoName.replace(/\s/g, '').concat('edit')}
                                                    title={video.videoName}>
                                                    <h6><i className="fas fa-bullhorn" ></i> Are You Sure You Want To Delete {video.videoName} ?</h6>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <Btn style="btn-sm btn-primary mx-1" text="Delete" onClick={() => deleteVideo(video.videoId)} />
                                                        </div>
                                                        <div className="col">
                                                            {this.state.deleteStatus === true ? (
                                                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                                    <p>Deleted successfully!</p>
                                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                            ) : (this.state.deleteStatus === false ? (
                                                                <div className="alert alert-primary alert-dismissible fade show" role="alert">
                                                                    <p>An Error Occured</p>
                                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                            ) : (null))
                                                            }
                                                        </div>
                                                    </div>
                                                </Modal>
                                                
                                                <div
                                                    style={{
                                                        background: copy.id === video.videoId && copy.status ? "green" :"#E50914",
                                                        padding:"5px 7.5px",
                                                        borderRadius:"100%",
                                                        cursor:"pointer"
                                                    }}
                                                    onClick={()=> this.handlerCopy(video.videoId)}
                                                >
                                                    <i style={{color:"white"}} className="fa fa-share"></i>
                                                </div>
                                        </div>

                                    </Card>
                                )}))}
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ cp }) => {
    return {
        cpVideos: cp.Videos.movies,
    }
};
export default connect(mapStateToProps, { getALLVideos, clearALLVideos })(Videos)

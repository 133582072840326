import React, {
  useRef,
} from "react";
import axios from "axios";
import TrailerUploader from "../../../components/uploaderEditSerie/trailer";

const AddTrailer = (props) => {
  const TrailerRef = useRef();
  const addTrailer = (e) => {
    e.preventDefault();
    const { updateVideoId, uploadTrailerTokens } = props.state;

    props.updateIsLoading2Handler(true);

    if (!uploadTrailerTokens) {
      props.updateIsLoading2Handler(false);
      return alert("Please upload Trailer");
    }
    axios({
      url: `${process.env.REACT_APP_API_URL}/trailer/new`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admintoken")}`,
      },
      data: {
        videoId: updateVideoId,
        trailer: uploadTrailerTokens,
      },
    })
      .then((response) => {
        props.updateIsLoading2Handler(false);
        if (response.status === 201) {
          alert("Trailer Uploaded Successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        props.updateIsLoading2Handler(false);
        alert(error.response.data.message);
      });
  };

  const handleTrailerUpload = (value) => {
    props.trailerUploadHandler(value);
  };

  return (
    <form
      style={{
        backgroundColor: "#FF9F9F",
      }}
      className="py-1 p-3 mt-3"
      onSubmit={addTrailer}
    >
      <div className="row">
        <div className="col-lg-6">
          <p>Trailer</p>
          <TrailerUploader
            ref={TrailerRef}
            type="image"
            handleTrailerUpload={handleTrailerUpload}
          />
        </div>
        <div className="col-lg-4">
          <button className="btn btn-primary mr-2" type="submit">
            {props.state.isLoading2 ? (
              <div
                className="spinner-border spinner-border-sm mr-2 mb-1"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            Upload Trailer
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddTrailer;

import React,{ useEffect, useState } from 'react';

export default ({ handler,cp,width }) => {
    const [ cps, setCps ] = useState([]);

    useEffect(()=> {
        fetch(`${process.env.REACT_APP_API_URL}/cp/all`,{
            method:"GET",
            headers:{ 
                Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`
            },})
        .then(res => res.json())
        .then(payload => setCps(payload.data))
        .catch(err => console.log(err));
        return () => setCps([]);
    } , [] )

    return(
        <div className={`col-lg-${width}`}>
            <h6 className="text-muted">Content Provider</h6>
            <select className="form-control" name="cps"  onChange={(e)=>handler(e.target.value)} value={cp} required>
                <option disabled selected>Choose</option>
                { cps ? cps[0] && cps.map(opt=><option value={opt.clientId}>{opt.name}</option>) : null}
            </select>
        </div>
    )
}
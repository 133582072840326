/* eslint-disable react/style-prop-object */
import axios from "axios";
import { connect } from "react-redux";
import React, { Component } from "react";
import Card from "../../components/card";
import Btn from "../../components/buttons";
import Modal from "../../components/modal";
import Episodes from "./helpers/episodes";
import EditSerie from "./helpers/editSerie";
import AddTrailer from "./helpers/addTrailer";
import "../../styles/components/progress.scss";
import Nav from "../../components/dashboard/nav";
import BarChart from "../../components/dashboard/barChart";
import { Loader } from "../../components/video/videoLoader";
import { getALLVideos, clearALLVideos } from "../../actions/cpActions";

class Series extends Component {
  state = {
    file: "",
    trailer: "",
    category: "",
    videoName: "",
    videoCast: "",
    videoSynopsis: "",
    videoEpisodes: [],
    updateVideoId: "",
    updateStatus: null,
    deleteStatus: null,
    barChartData: "views",
    videoStatistics: "views",
    copy: { id: "", status: false },

    isLoading: false,
    isLoading2: false,

    thumb: "",
    video: "",
    EnSubt: "",
    FrSubt: "",
    synopsis: "",
    episodeName: "",
    episodeNumber: 1,
    episodeSeason: "1",
    episodeVideoId: "",
    getSelectedSeason: "1",

    cpid: "",
    nameTag: "",
    portrait: "",
    landscape: "",
    progressBar: 1,
    searchQuery: "",
    uploading: null,
    progressOwner: "",
    resCountries: [],
    uploadTokens: null,
    licenseEndDate: null,
    contentProvider: "",
    uploadTrailerTokens: null,
  };

  currentVideo = {};

  componentDidMount() {
    if (!this.props.cpVideos[0]) this.props.getALLVideos();
  }

  getUpdateVideo = (id) => {
    this.setState({ updateVideoId: id, videoEpisodes: [] });
    axios
      .get(`${process.env.REACT_APP_API_URL}/video/cp/unique?id=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admintoken")}`,
        },
      })
      .then((res) => {
        const {
          videoName,
          videoCast,
          videoSynopsis,
          category,
          videoClientId,
          resCountries,
          licenseEndDate,
        } = res.data.data;
        this.setState({
          videoName,
          videoCast,
          videoSynopsis,
          category,
          cpid: videoClientId,
          resCountries,
          licenseEndDate,
        });
        fetch("https://restcountries.eu/rest/v2/all?fields=name;flag;region")
          .then((res) => res.json())
          .then(async (data) => {
            if (
              resCountries !== undefined &&
              resCountries !== null &&
              resCountries !== ""
            ) {
              const restrictions = resCountries.split(",");
              const arr = [];
              for (const country of data) {
                const { name } = country;
                const exist = await restrictions.find((el) => el === name);
                if (exist) {
                  arr.push({ ...country, back: "green" });
                } else {
                  arr.push({ ...country, back: "" });
                }
              }
              this.setState({ resCountries: arr });
            } else {
              this.setState({ resCountries: data });
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  fileChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  complexChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  getEpisodes = async (nber, vid) => {
    this.setState({ videoEpisodes: [] });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/series/single/season?s=${nber}&id=${vid}&&limit=40&offset=0`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("admintoken")}`,
          },
        }
      )
      .then((res) => {
        this.setState({ videoEpisodes: res.data.data });
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({ videoEpisodes: "empty" });
      });
  };

  deleteEpisode = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/series/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admintoken")}`,
        },
      })
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => console.log(err.response));
  };

  addEpisode = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.getUploadTokens("serie");
  };

  handlerCopy = (id) => {
    const el = document.createElement("input");
    el.value = "https://www.cinetie.com/video/" + id;
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ copy: { id, status: true } });
    setTimeout(() => this.setState({ copy: { id, status: false } }), 1000);
  };

  render() {
    const { cpVideos } = this.props;
    const { searchQuery, copy } = this.state;
    const today = new Date();
    const todayDate = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10 ? "0" : ""
    }${today.getMonth() + 1}-${
      today.getDate() < 10 ? "0" : ""
    }${today.getDate()}`;

    const deleteVideo = (id) => {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/video?id=${id}`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "admintoken"
            )}`,
          },
        })
        .then((res) => {
          this.setState({ deleteStatus: true });
          setTimeout(() => this.setState({ deleteStatus: null }), 2000);
        })
        .catch((err) => {
          this.setState({ deleteStatus: "File Was Already Deleted" });
          setTimeout(() => this.setState({ deleteStatus: null }), 2000);
        });
    };

    const modifyContentProviderState = ({ contentProvider }) => {
      this.setState({ contentProvider });
    };
    const modifyCategoryState = ({ category }) => {
      this.setState({ category });
    };
    const modifyRestrictCountryState = ({ resCountries: newCountries }) => {
      this.setState({ resCountries: newCountries });
    };
    const formChangeHandler = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };
    const landscapeUploadHandler = (value) => {
      this.setState((prevState) => ({
        ...prevState,
        landscape: value,
      }));
    };
    const portraitUploadHandler = (value) => {
      this.setState((prevState) => ({
        ...prevState,
        portrait: value,
      }));
    };
    const tagNameUploadHandler = (value) => {
      this.setState((prevState) => ({
        ...prevState,
        nameTag: value,
      }));
    };

    const updateStatusHandler = (updateStatus) => {
      this.setState({ updateStatus: true });
    };
    const updateIsLoadingHandler = (isLoading) => {
      this.setState((prevState) => ({
        ...prevState,
        isLoading: isLoading,
      }));
    };
    const updateIsLoading2Handler = (isLoading) => {
      this.setState((prevState) => ({
        ...prevState,
        isLoading2: isLoading,
      }));
    };

    const trailerUploadHandler = (value) => {
      this.setState((prevState) => ({
        ...prevState,
        uploadTrailerTokens: value,
      }));
    };

    return (
      <div className="hold">
        <div className="container-fluid text-body">
          <Nav page="All Serie" />
          <div className="content-header">
            <div className="input-field">
              <i className="fas fa-robot"></i>
              <input
                placeholder="Search Engine..."
                value={searchQuery}
                onChange={(e) => this.setState({ searchQuery: e.target.value })}
              />
              {searchQuery === "" ? (
                <i className="fas fa-search"></i>
              ) : (
                <i
                  onClick={() => this.setState({ searchQuery: "" })}
                  className="fas fa-times"
                ></i>
              )}
            </div>
            <div
              className="refresh"
              onClick={() => {
                this.props.clearALLVideos();
                this.props.getALLVideos();
              }}
            >
              <i className="fas fa-sync"></i>
            </div>
          </div>
          <div className="parent">
            {cpVideos === null ? (
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                No Data Present
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : cpVideos.length === 0 ? (
              <Loader />
            ) : (
              cpVideos.map((video) => {
                const { videoSuspensionState, videoName } = video;
                if (
                  !videoSuspensionState &&
                  videoName.toLowerCase().includes(searchQuery.toLowerCase())
                )
                  return (
                    <Card
                      size="3"
                      key={video.id}
                      img={video.videoThumbnailLink}
                      title={video.videoName}
                      licence={video.licenseEndDate}
                    >
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingLeft: "1em",
                          alignItems: "center",
                        }}
                      >
                        <Modal
                          embed={{ borderRadius: "20px", margin: "2%" }}
                          style="btn-primary btn-sm"
                          icon="fas fa-chart-line"
                          id={video.videoName.replace(/\s/g, "")}
                          title={video.videoName}
                          // icon="fas fa-chart-line"
                          footer={<p>Total Count : {video.videoWatchCount}</p>}
                        >
                          <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                onClick={() =>
                                  this.setState({ barChartData: "views" })
                                }
                                className="nav-link active"
                                id="views-tab"
                                data-toggle="tab"
                                href="#views"
                                role="tab"
                                aria-controls="views"
                                aria-selected="true"
                              >
                                Views
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                onClick={() =>
                                  this.setState({ barChartData: "earnings" })
                                }
                                className="nav-link"
                                id="earnings-tab"
                                data-toggle="tab"
                                href="#earnings"
                                role="tab"
                                aria-controls="earnings"
                                aria-selected="false"
                              >
                                Earnings
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content" id="myTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="views"
                              role="tabpanel"
                              aria-labelledby="views-tab"
                            >
                              {this.state.barChartData === "views" ? (
                                <BarChart id={video.videoId} type="views" />
                              ) : null}
                            </div>
                            <div
                              className="tab-pane fade"
                              id="earnings"
                              role="tabpanel"
                              aria-labelledby="earnings-tab"
                            >
                              {this.state.barChartData === "earnings" ? (
                                <BarChart
                                  id={video.videoId}
                                  type="cpVideoEarnings"
                                />
                              ) : null}
                            </div>
                          </div>
                        </Modal>
                        <Modal
                          onClick={() => this.getUpdateVideo(video.videoId)}
                          size="modal-xl"
                          style="btn-primary btn-sm"
                          icon="fas fa-binoculars"
                          embed={{ borderRadius: "20px", margin: "2%" }}
                          id={video.videoName.replace(/\s/g, "").concat("edit")}
                          title={video.videoName}
                        >
                          <div className="row grid-divider">
                            <div className="col-lg-7 divider">
                              <hr />

                              <EditSerie
                                updateIsLoadingHandler={updateIsLoadingHandler}
                                updateStatusHandler={updateStatusHandler}
                                tagNameUploadHandler={tagNameUploadHandler}
                                portraitUploadHandler={portraitUploadHandler}
                                landscapeUploadHandler={landscapeUploadHandler}
                                currentVideo={this.currentVideo}
                                formChangeHandler={formChangeHandler}
                                modifyRestrictCountryState={
                                  modifyRestrictCountryState
                                }
                                todayDate={todayDate}
                                modifyCategoryState={modifyCategoryState}
                                modifyContentProviderState={
                                  modifyContentProviderState
                                }
                                state={this.state}
                              />
                              <AddTrailer
                                updateIsLoading2Handler={
                                  updateIsLoading2Handler
                                }
                                trailerUploadHandler={trailerUploadHandler}
                                state={this.state}
                              />
                              <hr />
                              <Episodes
                                cpid={this.state.cpid}
                                videoId={this.state.updateVideoId}
                              />
                            </div>

                            <div className="col-lg-5 divider">
                              <h5>Episodes</h5>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      onChange={this.formChangeHandler}
                                      name="getSelectedSeason"
                                    >
                                      <option value="1">Season 1</option>
                                      <option value="2">Season 2</option>
                                      <option value="3">Season 3</option>
                                      <option value="4">Season 4</option>
                                      <option value="5">Season 5</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <Btn
                                    onClick={() =>
                                      this.getEpisodes(
                                        this.state.getSelectedSeason,
                                        video.videoId
                                      )
                                    }
                                    style="btn-primary btn-sm mb-2"
                                    text="Get Season's Episodes"
                                  />
                                </div>
                              </div>
                              {this.state.videoEpisodes === "loading" ? (
                                <p>Loading, Please Wait....</p>
                              ) : this.state.videoEpisodes === "empty" ? (
                                <p>No Episodes Yet, Please Upload Some</p>
                              ) : this.state.videoEpisodes.length !== 0 ? (
                                this.state.videoEpisodes.map((episode) => (
                                  <div key={episode.id}>
                                    <div className="row pr-3">
                                      <div className="col pt-2">
                                        <p>{episode.episodeName}</p>
                                      </div>
                                      <div className="col text-right">
                                        <Btn
                                          onClick={() => {
                                            if (
                                              window.confirm("Are You sure")
                                            ) {
                                              this.deleteEpisode(episode.id);
                                            }
                                          }}
                                          style="btn-primary btn-sm"
                                          icon="fa-trash"
                                        />
                                      </div>
                                    </div>
                                    <hr />
                                  </div>
                                ))
                              ) : null}
                            </div>
                          </div>
                        </Modal>
                        <Modal
                          size="modal-xl"
                          style="btn-primary btn-sm"
                          icon="fas fa-trash"
                          embed={{ borderRadius: "20px", margin: "2%" }}
                          id={video.videoName.replace(/\s/g, "").concat("edit")}
                          title={video.videoName}
                        >
                          <h6>
                            <i className="fas fa-bullhorn"></i> Are You Sure You
                            Want To Delete {video.videoName} ?
                          </h6>
                          <div className="row">
                            <div className="col-lg-3">
                              <Btn
                                style="btn-sm btn-primary mx-1"
                                text="Delete"
                                onClick={() => deleteVideo(video.videoId)}
                              />
                            </div>
                            <div className="col">
                              {this.state.deleteStatus === true ? (
                                <div
                                  className="alert alert-success alert-dismissible fade show"
                                  role="alert"
                                >
                                  <p>Deleted successfully!</p>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              ) : this.state.deleteStatus === false ? (
                                <div
                                  className="alert alert-primary alert-dismissible fade show"
                                  role="alert"
                                >
                                  <p>An Error Occured</p>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Modal>
                        <div
                          style={{
                            background:
                              copy.id === video.videoId && copy.status
                                ? "green"
                                : "#E50914",
                            padding: "5px 7.5px",
                            borderRadius: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => this.handlerCopy(video.videoId)}
                        >
                          <i
                            style={{ color: "white" }}
                            className="fa fa-share"
                          ></i>
                        </div>
                      </div>
                    </Card>
                  );
              })
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cp }) => {
  return {
    cpVideos: cp.Videos.series,
  };
};
export default connect(mapStateToProps, { getALLVideos, clearALLVideos })(
  Series
);

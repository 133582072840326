import React , { useState,useEffect } from 'react';
import '../../../styles/components/categories.scss';

const Categories = ({ width,set,state }) => {
    const [ categories,setCatg ] = useState([
        {name:'Short-Film', picked:false},
        {name:'Talk-Show', picked:false},
        {name:'Documentary', picked:false},
        {name:'Action', picked:false},
        {name:'Thriller', picked:false},
        {name:'Romance', picked:false},
        {name:'Sci-Fi', picked:false},
        {name:'Horror', picked:false},
        {name:'Sitcom', picked:false},
        {name:'Drama', picked:false},
        {name:'Stand-up', picked:false},
        {name:'Adventure', picked:false},
        {name:'Comedy', picked:false},
        {name:'Musical', picked:false},
        {name:'Kids', picked:false},
        {name:'Historical', picked:false},
        {name:'science', picked:false},
        {name:'magic', picked:false},
        {name:'suspenseful', picked:false},
        {name:'noir ', picked:false},
        {name:'martial arts', picked:false},
        {name:'cartoon', picked:false},
        {name:'bibliographical', picked:false},
        {name:'disaster', picked:false},
        {name:'mystery', picked:false},
        {name:'epic', picked:false},
        {name:'short', picked:false},
        {name:'war', picked:false},
        {name:'western', picked:false},
        {name:'children', picked:false},
        {name:'family', picked:false},
        {name:'Heroes', picked:false},
        {name:'reality', picked:false},
        {name:'fantasy', picked:false},
        {name:'fiction', picked:false},
        {name:'animation', picked:false},
        {name:'crime', picked:false},
    ]);


    const [ showModal,setModal ] = useState(false)

    const handlerCheck = (name) =>{

        if(state !== "Choose"){
            const arr = state.split(",");
            const newArr = [];
            let exists = false;

            for(const el of arr){
                if(el !== name){
                    newArr.push(el);
                }else{
                    exists = true;
                }
            }

            if(exists){
                set(newArr.toString())
            }else{
                set([...newArr,name].toString())
            }

        }else{
            set(name)
        }
    }

    useEffect(()=>{
        const arr = state.split(",");
        setCatg((prevState,Props)=>{
            return prevState.map(el=>{
                const exists = arr.find(name => name === el.name )
                if(exists)
                    return { ...el,picked:true }
                else
                    return { ...el,picked:false }
            })
        })
    },[state])


    return (
        <div className={`col-lg-${width} category-parent`}>
            <h6 onClick={()=> setModal(true)} >Choose</h6>
            <i className="opener fas fa-chevron-down" onClick={()=> setModal(true)}></i>
            {
                showModal &&
                <div className="categories">
                <i className="closer fas fa-times" onClick={()=> {
                    const tag = document.querySelector('.categories');
                    tag.style.animation = 'Out 0.5s ease-in-out'
                    setTimeout(()=>setModal(false),400)
                }}></i>
                    {categories.map((item,index)=>
                        <div onClick={()=>handlerCheck(item.name)} className={item.picked ? "category picked" : "category" } key={index}>
                            <span>{item.name}</span>
                            {
                                !item.picked ?
                                <i className="checker fas fa-check" ></i> :
                                <i className="unCheck fas fa-times" ></i>
                            }
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default Categories; 
import React from 'react';
import store from './store';
import { Provider } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import ErrorNotFound from './views/notfound';
import AdminSignin from './views/admin/signin';
import AdminDashboard from './views/admin/dashboard';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';


const GpsTracker = () => {
  fetch("https://cloudflare.com/cdn-cgi/trace")
  .then(res => res.text())
  .then( response => {  
    // console.log(response.split(""))
    const ip = response.split("=")[3].split("ts")[0].trim();
    const location = response.split("=")[9].split("")[0] + response.split("=")[9].split("")[1];
    
    //setting ip to localstorage
    localStorage.setItem("ip",ip)

    //getting country with ip
    fetch(`https://restcountries.eu/rest/v2/alpha/${location}`)
    .then(res => res.json())
    .then(data => { 
      const{ name } = data;
      localStorage.setItem("country",name)
    })
    .catch(err=> console.log(err)) 
  })
  .catch(err => console.log(err))
}

function App() {
  
  GpsTracker();

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={AdminSignin} />
          <PrivateRoute exact path="/signin" component={AdminSignin} />
          <PrivateRoute exact path="/dashboard" component={AdminDashboard} />
          <Route component={ErrorNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getViews, getCpVideoEarnings, getEarningsCp, getSales } from '../../actions/barChartActions';
import Charts from './charts';

class Chart extends Component {
    state = {
        Displaydata: null,
        params: []
    }

    componentDidMount() {
        switch (this.props.type) {
            case "views":
                this.setState({ Displaydata: 'views' })
                this.setState({ params: ['city', 'count', 'count'] })
                this.props.getViews(this.props.id)
                break
            case "cpVideoEarnings":
                this.setState({ Displaydata: 'cpVideoEarnings' })
                this.setState({ params: ['period', 'amount', 'amount'] })
                this.props.getCpVideoEarnings(this.props.id)
                break
            case "cpEarnings":
                this.setState({ Displaydata: 'cpEarnings' })
                this.setState({ params: ['period', 'amount', 'amount'] })
                this.props.getEarningsCp(this.props.id)
                break
            case "sales":
                this.setState({ Displaydata: 'Sales' })
                this.setState({ params: ['period', 'amount', 'amount'] })
                this.props.getSales()
                break
            default:
                return
        }

    }

    render() {
        const { barChartData } = this.props.barChart;
        return (
            <div style={{width:"90%"}}>
                { barChartData.data && <Charts payload={barChartData}/>}
            </div>
        )
    }
}

const mapStateToProps = ({ barChart }) => ({ barChart });
export default connect(mapStateToProps, { getViews, getCpVideoEarnings, getEarningsCp, getSales })(Chart)
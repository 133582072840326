/* eslint-disable react/style-prop-object */
import React, { Component } from 'react'
import axios from 'axios'
import Nav from '../../components/dashboard/nav'
import DataTable from '../../components/dashboard/dataTable';
import Btn from '../../components/buttons';

export default class payments extends Component {
	state = {
		rows: [],
		columns: []
	}

	componentDidMount() {
		this.getTransactions()
	}

	approvePayment = (id) => {
		axios.put(`${process.env.REACT_APP_API_URL}/cp/trans/approve?id=${id}`, null, {
			headers: {
				Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`
			}
		})
			.then(response => {
				alert("Payment Approved SuccessFully !")
				console.log(response)
				this.getTransactions()
			})
			.catch(error => {
				alert("An Error Occured")
				console.log(error.response)
			});
	}

	getTransactions = () => {
		const actionsFormatter = (cell, row) => {
			return (
				<>
					{row.state === 'Approved' ?
						(<Btn style="btn-success" text="Approved" disabled="disabled" />)
						:
						(<Btn onClick={() => { this.approvePayment(row.id) }} style="btn-primary" text="Approve" />)}
				</>
			)
		};
		axios.get(`${process.env.REACT_APP_API_URL}/cp/trans/all`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		})
			.then((res) => {
				this.setState({
					rows: res.data.data, columns: [{
						dataField: 'id',
						text: 'ID',
						sort: true
					}, {
						dataField: 'clientId',
						text: 'Client Name',
						sort: true
					},
					{
						dataField: 'date',
						text: 'Transaction Date',
						sort: true
					},
					{
						dataField: 'amount',
						text: 'Amount (USD)',
						sort: true
					}, {
						dataField: '',
						text: 'Action',
						isDummyField: true,
						formatter: actionsFormatter
					},
						// {
						// 	dataField: 'state',
						// 	text: 'State',
						// 	sort: true
						// }
					]
				})
			})
			.catch(err => console.log(err.response))
	}
	render() {
		return (
			<div>
				<div className="container-fluid text-body">
					<Nav page="Payments" />

					<div className="row px-3">
						<DataTable rows={this.state.rows} columns={this.state.columns} />
					</div>
				</div>
			</div>
		)
	}
}

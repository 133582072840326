import axios from 'axios';
import { DISPLAY_VIDEO_VIEWS, DISPLAY_CP_VIDEO_EARNINGS, DISPLAY_CP_EARNINGS, DISPLAY_ADMIN_SALES } from './actionType';

export const getViews = (id) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/sessions/video?videoId=${id}`, {
        headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
    })
        .then((res) => {
            dispatch({
                type: DISPLAY_VIDEO_VIEWS,
                payload: res.data.data.formattedData,
                total: res.data.data.totalCount
            });
        })
        .catch(err => console.log(err.response))
};

export const getCpVideoEarnings = (id) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/video/earnings?videoId=${id}`, {
        headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
    })
        .then((res) => {
            dispatch({
                type: DISPLAY_CP_VIDEO_EARNINGS,
                payload: res.data.data.data,
                total: res.data.data.sum
            });
        })
        .catch(err => console.log(err.response))
};

export const getEarningsCp = (id) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/admin/earnings/cp?clientId=${id}`, {
        headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
    })
        .then((res) => {
            dispatch({
                type: DISPLAY_CP_EARNINGS,
                payload: res.data.data.data,
                total: res.data.data.sum
            });
        })
        .catch(err =>{
            if (err.response.status === 404) {
                dispatch({
                    type: DISPLAY_CP_EARNINGS,
                    payload: null
                });
            }
        })
};

export const getSales = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/admin/sales/all`, {
        headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
    })
        .then((res) => {
            dispatch({
                type: DISPLAY_ADMIN_SALES,
                payload: { data: res.data.data.data, sum : res.data.data.sum },
                total: res.data.data.sum
            });
        })
        .catch(err => {
            if (err.response.status === 404) {
                dispatch({
                    type: DISPLAY_ADMIN_SALES,
                    payload: null
                });
            }
        })
};
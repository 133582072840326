/* eslint-disable react/style-prop-object */
import React, { Component } from 'react'
import axios from 'axios'
import Nav from '../../components/dashboard/nav'
import Btn from '../../components/buttons'
import Modal from '../../components/modal'
import ModalData from '../../components/modalData'
import BarChart from '../../components/dashboard/barChart';
import DataTable from '../../components/dashboard/dataTable';
import Loader from '../../components/loader'



export default class CP extends Component {
    state = {
        rows: [],
        columns: [],
        isVideos: null,
        videos: { rows: [], columns: [] },
        showModal: false,
    }

    handleModalShowClick(e) {
        e.preventDefault();
        this.setState({
            showModal: true
        })
    }

    handleModalCloseClick() {
        this.setState({
            showModal: false
        })
    }
    componentDidMount() {
        this.getAllCP()
    }

    getAllCP = () => {
        const actionsFormatter = (cell, row) => {
            return (
                <>
                    <Modal
                        style="btn-secondary" text="ID"
                        id="ViewID"
                        title={`${row.name} 's ID`}>
                        <img src={row.idLink} alt="" className="img-fluid" />
                    </Modal>
                    <Modal
                        style="btn-secondary" text="Earnings"
                        id="ViewEarnings"
                        title={`${row.name} 's Earnings`}>
                        <BarChart id={row.clientId} type="cpEarnings" />
                    </Modal>
                </>
            )
        };
        axios.get(`${process.env.REACT_APP_API_URL}/cp/all`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
            .then((res) => {
                this.setState({
                    rows: res.data.data,
                    columns: [{
                        dataField: 'name',
                        text: 'Names',
                        sort: true
                    }, {
                        dataField: 'phoneLine',
                        text: 'Phone',
                        sort: true
                    },
                    {
                        dataField: 'email',
                        text: 'Email',
                        sort: true
                    }, {
                        dataField: 'idLink',
                        text: 'Data',
                        isDummyField: true,
                        formatter: actionsFormatter
                    }]
                });
            })
            .catch(err => console.log(err.response))
    }

    getCpToken = (id, email) => {
        console.log(id, email);
        axios.post(`${process.env.REACT_APP_API_URL}/admin/gain-access`,
        { email: email, clientId: id }, { headers: {Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`} })
        .then(response => {
           window.open(`https://cp.cinetie.com/cp/access/${response.data.data}`)
        })
        .catch(error => {
           alert(error.response);
        });
    }


    getCpVideos = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/admin/videos/cp?clientId=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
        })
            .then((res) => {
                const videos = {
                    rows: res.data.data, columns: [{
                        dataField: 'id',
                        text: 'ID',
                        sort: true
                    }, {
                        dataField: 'videoName',
                        text: 'Movie Name',
                        sort: true
                    },
                    {
                        dataField: 'videoWatchCount',
                        text: 'Total Views',
                        sort: true
                    },
                    {
                        dataField: 'videoSuspensionState',
                        text: 'State',
                        sort: true
                    }, {
                        dataField: 'creationTime',
                        text: 'Created'
                    }]
                }
                console.log("Videos retrieved")
                this.setState({ videos, showModal: true });

            })
            .catch(err => console.log(err.response))
    }


    render() {
        const { showModal } = this.state;
        return (
            <div>
                <div className="container-fluid text-body">
                    <Nav page="Content Providers" />

                    {showModal ? (
                        <ModalData id={'id'} title={'title'} handleModalCloseClick={this.handleModalCloseClick.bind(this)} >
                            <DataTable rows={this.state.videos.rows} columns={this.state.videos.columns} />
                        </ModalData>) : null}
                    <div className="row">
                        {this.state.rows === 0 ? (<Loader />) : (
                            <DataTable rows={this.state.rows} columns={this.state.columns} />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

/* eslint-disable react/style-prop-object */
import React, { Component } from 'react'
import ModalData from './modalData'
export default class Modal extends Component {
    constructor(props) {
        super(props);
        this.handleModalShowClick = this.handleModalShowClick.bind(this);
        this.handleModalCloseClick = this.handleModalCloseClick.bind(this);
        this.state = {
            showModal: false,
        }
    }

    handleModalShowClick(e) {
        // e.preventDefault();
        this.setState({
            showModal: true
        })
    }

    handleModalCloseClick() {
        this.setState({
            showModal: false
        })
    }
    render() {
        const { showModal } = this.state;
        const { size, id, title, footer,style,embed } = this.props;
        return (
            <>
                <button className={`btn ${style} my-1 mx-1`} 
                    style ={embed} onClick={() => {
                    if (this.props.onClick) { this.props.onClick() }
                    this.handleModalShowClick()
                }
                } data-toggle="modal" data-target={`#${this.props.id}`}>
                    {this.props.icon != null ? (<i className={`fa ${this.props.icon}`}></i>) : (null)} {this.props.text}
                </button>
                {showModal ? (
                    <ModalData size={size} id={id} title={title} footer={footer} handleModalCloseClick={this.handleModalCloseClick} >
                        {this.props.children}
                    </ModalData>) : null}
            </>
        )
    }
}

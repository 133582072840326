import axios from 'axios';
import { DISPLAY_All_VIDEOS, DISPLAY_TOTAL_EARNINGS, DISPLAY_ACTIVE_EARNINGS } from './actionType';

export const getCPStats = () => (dispatch) => {
  axios.get(`${process.env.REACT_APP_API_URL}/cp/earnings`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('cptoken')}` }
  })
    .then((res) => {
      dispatch({
        type: DISPLAY_TOTAL_EARNINGS,
        payload: res.data.data.sum
      });
    })
    .catch(err => console.log(err.response))

  axios.get(`${process.env.REACT_APP_API_URL}/cp/earnings/active`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('cptoken')}` }
  })
    .then((res) => {
      dispatch({
        type: DISPLAY_ACTIVE_EARNINGS,
        payload: res.data.data
      });
    })
    .catch(err => console.log(err.response))
};

export const getALLVideos = () => (dispatch) => {
  axios.get(`${process.env.REACT_APP_API_URL}/video/all/local`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('admintoken')}` }
  })
  .then((res) => 
    dispatch({ type: DISPLAY_All_VIDEOS, payload: res.data.data })
  )
  .catch(err => console.log(err))
};

export const clearALLVideos = () => (dispatch) => {
    dispatch({ type: "ClearAll", payload: "" })
}

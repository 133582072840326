import React from 'react'

export default function Loader() {
    return (
        <div className="py-5 mx-auto text-center">
            <div className="spinner-border text-primary" style={{ width: '2rem', height: '2rem' }} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  Component,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import TagNameUploader from "../../../components/uploaderEditSerie/tagName";
import PortraitUploader from "../../../components/uploaderEditSerie/portrait";
import LandscapeUploader from "../../../components/uploaderEditSerie/landScape.jsx";
import En_subtitleUploader from "../../../components/uploaderEditSerie/En_Subtitle";
import Fr_subtitleUploader from "../../../components/uploaderEditSerie/Fr_Subtitle";

import CPS from "../helpers/contentProviders";
import Categories from "../helpers/categories";
import Restriction from "../helpers/restrictions";

const EditSerie = (props) => {
  const videoRef = useRef();
  const TagNameRef = useRef();
  const LandscapeRef = useRef();
  const PortraitRef = useRef();
  const En_subtitleRef = useRef();
  const Fr_subtitleRef = useRef();

  const restrict = async (type, data) => {
    const { resCountries } = props.state;

    if (type === "Grant") {
      if (Array.isArray(data)) {
        const newCountries = [];
        for (let i = 0; i < resCountries.length; i++) {
          if (resCountries[i].region === data[0]) {
            newCountries.push({ ...resCountries[i], back: "green" });
          } else {
            newCountries.push(resCountries[i]);
          }
        }
        props.modifyRestrictCountryState({ resCountries: newCountries });
      } else {
        const newCountries = await resCountries.map((el) => {
          if (el.name !== data) {
            return el;
          } else {
            return { ...el, back: "green" };
          }
        });
        props.modifyRestrictCountryState({ resCountries: newCountries });
      }
    } else {
      if (Array.isArray(data)) {
        const newCountries = [];
        for (let i = 0; i < resCountries.length; i++) {
          if (resCountries[i].region === data[0]) {
            newCountries.push({ ...resCountries[i], back: "" });
          } else {
            newCountries.push(resCountries[i]);
          }
        }
        props.modifyRestrictCountryState({ resCountries: newCountries });
      } else {
        const newCountries = await resCountries.map((el) => {
          if (el.name !== data) {
            return el;
          } else {
            return { ...el, back: "" };
          }
        });
        props.modifyRestrictCountryState({ resCountries: newCountries });
      }
    }
  };

  const updateVideo = async (e) => {
    e.preventDefault();

    let videoUpdate = {};
    const {
      videoName,
      videoCast,
      videoSynopsis,
      category,
      resCountries,
      contentProvider,
      landscape,
      nameTag,
      portrait,
      licenseEndDate,
    } = props.state;

    props.updateIsLoadingHandler(true);

    if (videoName !== "" && videoName !== props.currentVideo.videoName) {
      videoUpdate.videoName = videoName;
    }
    if (videoCast !== "" && videoCast !== props.currentVideo.videoCast) {
      videoUpdate.videoCast = videoCast;
    }
    if (licenseEndDate) {
      videoUpdate.licenseEndDate = licenseEndDate;
    }
    if (
      videoSynopsis !== "" &&
      videoSynopsis !== props.currentVideo.videoSynopsis
    ) {
      videoUpdate.videoSynopsis = videoSynopsis;
    }
    if (category !== "" && category !== props.currentVideo.category) {
      videoUpdate.category = category;
    }

    if (landscape !== "" || nameTag !== "" || portrait !== "") {
      if (landscape !== "") {
        videoUpdate = {
          ...videoUpdate,
          videoThumbnailLink: landscape,
        };
      }
      if (portrait !== "") {
        videoUpdate = {
          ...videoUpdate,
          tallThumbNailLink: portrait,
        };
      }
      if (nameTag !== "") {
        videoUpdate = {
          ...videoUpdate,
          nameTagThumbNailLink: nameTag,
        };
      }
    }

    if (contentProvider !== "") {
      videoUpdate = {
        ...videoUpdate,
        contentProvider,
      };
    }

    const countries = [];
    for (const el of resCountries) {
      if (el.back === "green") {
        countries.push(el.name);
      }
    }

    videoUpdate = {
      ...videoUpdate,
      resCountries:
        countries.toString() === "" ? "valhara" : countries.toString(),
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/video?id=${props.state.updateVideoId}`,
        videoUpdate,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("admintoken")}`,
          },
        }
      )
      .then((res) => {
        props.updateIsLoadingHandler(false);
        props.updateStatusHandler(true);
        alert("res.data.message");
      })
      .catch((err) => {
        console.log(err);
        props.updateIsLoadingHandler(false);
        props.updateStatusHandler(false);
      });
  };

  const handleTagNameUpload = (value) => {
    props.tagNameUploadHandler(value);
  };

  const handlePortraitUpload = (value) => {
    props.portraitUploadHandler(value);
  };
  const handleLandscapeUpload = (value) => {
    props.landscapeUploadHandler(value);
  };

  return (
    <form onSubmit={updateVideo}>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <h6 className="text-muted">Video Name</h6>
            <input
              type="text"
              className="form-control"
              name="videoName"
              onChange={props.formChangeHandler}
              value={props.state.videoName}
            />
          </div>
        </div>
        <CPS
          width={6}
          handler={(contentProvider) =>
            props.modifyContentProviderState({ contentProvider })
          }
          cp={props.state.contentProvider}
        />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <h6 className="text-muted">Video Cast</h6>
            <textarea
              maxLength="150"
              style={{ resize: "none" }}
              className="form-control"
              rows="2"
              onChange={props.formChangeHandler}
              name="videoCast"
              value={props.state.videoCast}
            ></textarea>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <h6 className="text-muted">Video Synopsis</h6>
            <textarea
              maxLength="250"
              style={{ resize: "none" }}
              className="form-control"
              rows="2"
              onChange={props.formChangeHandler}
              name="videoSynopsis"
              value={props.state.videoSynopsis}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "2%" }}>
        <div className="col-lg-6">
          <p> 1920 x 1080 Landscape Cover</p>
          <LandscapeUploader
            ref={LandscapeRef}
            type="image"
            handleLandscapeUpload={handleLandscapeUpload}
          />
        </div>
        <div className="col-lg-6">
          <p>1080 x 1620 Portrait Cover</p>
          <PortraitUploader
            ref={PortraitRef}
            type="image"
            handlePortraitUpload={handlePortraitUpload}
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: "2%" }}>
        <div
          className="col-lg-6"
          style={{
            marginTop: "50px",
          }}
        >
          <p>320 x 320 Video Tag Name</p>
          <TagNameUploader
            ref={TagNameRef}
            handleTagNameUpload={handleTagNameUpload}
            type="image"
          />
        </div>
      </div>
      <br />
      <h5 className="text-muted">Categories</h5>
      <Categories
        state={props.state.category}
        width={5}
        set={(category) => props.modifyCategoryState({ category })}
      />
      <br />
      <div className="row">
        <div className="col-lg-4">
          <div className="form-group">
            <h5 className="text-muted">License end date</h5>
            <input
              min={props.todayDate}
              type="date"
              name="licenseEndDate"
              onChange={props.formChangeHandler}
              className="form-control"
              rows="2"
            />
          </div>
        </div>
      </div>
      <br />
      <Restriction
        handler={restrict}
        allCountries={props.state.resCountries}
        Alignment="grid"
      />

      <div className="row">
        <div className="col">
          <button className="btn btn-primary mr-2" type="submit">
            {props.state.isLoading ? (
              <div
                className="spinner-border spinner-border-sm mr-2 mb-1"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            Update Video Data
          </button>
        </div>

        <div className="col">
          {/* {props.state.updateStatus === true ? (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <h6>Updated successfully!</h6>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : props.state.updateStatus === false ? (
            <div
              className="alert alert-primary alert-dismissible fade show"
              role="alert"
            >
              <h6>An Error Occured</h6>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : null} */}
        </div>
      </div>
    </form>
  );
};

export default EditSerie;

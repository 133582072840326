/* eslint-disable no-console */
import { GET_USERS_IN_RANGE, GET_LIVE_USERS, GET_ALL_TRANSACTIONS } from '../actions/actionType';

const initState = {
  liveUsers: null,
  usersInRange: null,
  allTransactions: []
};

const adminStatisticsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USERS_IN_RANGE:
      return {
        ...state,
        usersInRange: action.payload,
      };
    case GET_LIVE_USERS:
      return {
        ...state,
        liveUsers: action.payload,
      };
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions: action.payload,
      };

    default:
      return state;
  }
};

export default adminStatisticsReducer;

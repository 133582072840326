import React from 'react'
import redmark from '../assets/icons/redmark.svg';
import '../styles/components/card.scss';
import compareLicenseDate from '../views/admin/helpers/LicenseDateCompare';

function ExpirationSpan({ days }) {
    return (days >= 0) ? (<span>{days} day left</span>) : <span>expired</span>;
}
export default function Card(props) {
    const licenseData = compareLicenseDate(props.licence)
    return (
        <div className="lcard">
            <div className={`dcard mt-3 ${props.className}`}>
                {licenseData.nearExpiration ? (<div id='license'><ExpirationSpan days={licenseData.daysLeft} /><img id="redmark" src={redmark} /></div>) : null}
                {props.img ? (<img src={props.img} alt="" className="card-img-top card-img" />) : (null)}
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import AwsS3 from "@uppy/aws-s3";
import axios from "axios";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const uppy = new Uppy({
  restrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ["image/*"],
  },
  autoProceed: false,
  showLinkToFileUploadResult: false,
  showRemoveButtonAfterComplete: true,
  proudlyDisplayPoweredByUppy: true,
});

const TagNameUploader = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    reset() {
      uppy.reset();
    },
  }));

  
  let rx = Math.random().toString(36).substring(7);
  const random = `uppy-uploader-${rx}`;

  useEffect(() => {
    uppy.use(AwsS3, {
      id: random,
      fields: [],
      getUploadParameters(file) {
        return axios
          .get(
            `${process.env.REACT_APP_API_URL}/temp/signed-urls?type=${props.type}&&extension=${file.extension}`,
            {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "admintoken"
                )}`,
              },
            }
          )
          .then((res) => {
            props.handleTagNameUpload(res.data.data.nameTag.Key);
            return res;
          })
          .then((data) => {
            return {
              method: "put",
              url: data.data.data.nameTag.url,
              headers: { "Access-Control-Allow-Origin": "*" },
            };
          });
      },
    });
  }, []);

  return (
    <>
      <Dashboard
        key={props.id}
        uppy={uppy}
        width={520}
        height={190}
        open={false}
      />
    </>
  );
});

export default TagNameUploader;

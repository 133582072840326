import React from 'react'

export default function Nav(props) {
    return (
        <>
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <a href="#">Admin Dashboard</a>
                </li>
                <li className="breadcrumb-item active">{props.page}</li>
            </ol>
            <h4>{props.page} Page</h4>
            
            <hr />
        </>
    )
}

import React, { Component } from 'react'
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

export default class modalData extends Component {
    constructor(props) {
        super(props);
        this.handleCloseClick = this.handleCloseClick.bind(this);
    }
    componentDidMount() {
        const { handleModalCloseClick } = this.props;
        $(this.modal).modal('show');
        $(this.modal).on('hidden.bs.modal', handleModalCloseClick);
    }
    handleCloseClick() {
        const { handleModalCloseClick } = this.props;
        $(this.modal).modal('hide');
        handleModalCloseClick();
    }

    render() {
        return (
            <React.Fragment>
                <div ref={modal => this.modal = modal} className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className={`modal-dialog ${this.props.size === "small" ? (null) : (this.props.size || 'modal-lg')} modal-dialog-centered`} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel">{this.props.title}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.props.children}
                            </div>
                            <div className="modal-footer">
                                {this.props.footer}
                                {/* <button type="button" className="btn btn-secondary" onClick={this.handleCloseClick}>Close</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

/* eslint-disable react/style-prop-object */
import React, { Component } from 'react'
import axios from 'axios'
import Nav from '../../components/dashboard/nav'
import DataTable from '../../components/dashboard/dataTable';

class Videos extends Component {

	state = {
		videos: { rows: [], columns: [] },
		video: '',
		featureType: null,
	}

	componentDidMount() {
		this.getAllVideos()
	}

	formChangeHandler = (e) => {
		this.setState({ featureType: e.target.value })
	}

	setFeatured = (id, type) => {
		if (this.state.featureType !== null) {
			axios.put(`${process.env.REACT_APP_API_URL}/video/set-featured?id=${id}`, {
				isFeatured: true,
				featureType: this.state.featureType,
				type
			}, {
				headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
			})
				.then(response => {
					alert("Movie Set As Featured SuccessFully !")
					console.log(response)
					this.setState({
						videos: { rows: [], columns: [] },
						video: '',
						featureType: null,
					});
					this.getAllVideos()
				})
				.catch(error => {
					alert("An Error Occured")
					console.log(error.response)
				});
		}
		else alert('Input Valid Featured Type')

	}

	getAllVideos = () => {
		const actionsFormatter = (cell, row) => {
			return (
				<>
					<form onSubmit={(e) => { e.preventDefault(); this.setFeatured(row.videoId, row.type) }}>
						<div className="col pb-1 px-2">
							<div className="form-group">
								<select className="form-control" onChange={this.formChangeHandler} value={this.state.featureType} required >
									<option value={null}>Choose</option>
									<option value={1}>Type 1</option>
									<option value={2}>Type 2</option>
								</select>
							</div>
						</div>
						<div className="px-2">
							<button className="btn btn-primary col" type="submit">Set Featured</button>
						</div>
					</form>
				</>
			)
		};
		axios.get(`${process.env.REACT_APP_API_URL}/video/all/local`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		})
			.then((res) => {
				const videos = {
					rows: res.data.data, columns: [{
						dataField: 'id',
						text: 'ID',
						sort: true
					}, {
						dataField: 'videoName',
						text: 'Movie Name',
						sort: true
					},
					{
						dataField: 'type',
						text: 'Movie Type',
						sort: true
					},
					{
						dataField: 'videoWatchCount',
						text: 'Watch Count',
						sort: true
					},
					{
						dataField: 'videoSuspensionState',
						text: 'Set Featured',
						isDummyField: true,
						formatter: actionsFormatter
					}]
				}
				console.log("Videos retrieved")
				this.setState({ videos });

			})
			.catch(err => {
				console.log(err.response)
				this.setState({ videos: { columns: null } })
			})
	}
	render() {
		return (
			<div>
				<div className="container-fluid text-body">
					<Nav page="Featuring" />
					<div className="row px-3">
						<DataTable rows={this.state.videos.rows} columns={this.state.videos.columns} />
					</div>

				</div>
			</div>
		)
	}
}

export default Videos
import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Loader from '../../components/loader'

class DataTable extends Component {

  customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total px-3">
      Showing {from} to {to} of {size} Results
    </span>
  );
  options = {
    paginationSize: 4,
    pageStartIndex: 0,
    showTotal: true,
    paginationTotalRenderer: this.customTotal,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: this.props.columns.length
    }]
  }

  render() {
    const { rows, columns } = this.props
    return (
      <>
        {columns === null ? (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            No Data Present
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : columns.length === 0 ? (<Loader />) :
            (<BootstrapTable bootstrap4={true} keyField='id' data={rows} columns={columns} pagination={paginationFactory(this.options)} />)}
      </>
    )
  }
}

export default DataTable